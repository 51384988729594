@use "../global" as *;

#pageService.quality {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-copy {
    padding: 8rem 0 7rem;

    .copy-wrap {
      .ttl-box {
        text-align: center;

        .ttl {
          line-height: 1.4;
        }

        .note {
          font-size: 1.1rem;
          margin: 2rem 0 0;
        }
      }

      .txt-box {
        text-align: center;
        margin: 5rem 0 0;
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-copy {
      padding: 9rem 0 8rem;

      .copy-wrap {
        .ttl-box {
          // text-align: center;

          .ttl {
            // line-height: 1.4;
          }

          .note {
            font-size: 2.2rem;
            margin: 3rem 0 0;
          }
        }

        .txt-box {
          // text-align: center;
          margin: 6rem 0 0;
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-qcs {
    padding: 11rem 0 0;
    background: #E0F0FE;

    .ttl-wrap {
      margin: 0 0 4rem;

      .ttl-box {
        .h2 {
          position: relative;

          img {
            width: 24rem;
          }
        }

        .h2::before {
          @include posi_c_r;
          content: "";
          width: calc(100% - 26rem);
          height: 1px;
          background: #D9D9D9;
        }

        .note {
          font-size: 1.1rem;
          margin: 1rem 0 0;
        }
      }
    }

    .qcs-wrap {
      .label-area {
        .label-box {
          @include flex_c_c;
          height: 7rem;
          background: $clr_blu;
          color: #fff;

          .h3 {
            text-align: center;

            span {
              font-size: 1.2rem;
            }
          }
        }

        .label-box.h-8 {
          height: 8rem;

          .note {
            font-size: 1.4rem;
            line-height: 1.4;
            margin: 0 0 0 5rem;
          }
        }
      }

      .list-area-outer {}

      .list-area {
        padding: 1rem 4rem 12rem;
        position: relative;
        overflow: hidden;

        .line-box {
          @include posi_t_l;
          left: calc(4rem + 20rem + 12rem - 6rem - 1px);
          width: 2px;
          height: 100%;
          background: #fff;
        }

        .list-box {
          @include flex;
          position: relative;

          .ttl {
            @include flex_c_c;
            flex-direction: column;
            width: 20rem;
            height: 18rem;
            text-align: center;
            position: relative;
            z-index: 2;
          }

          .ttl-ttl {
            font-size: 2.5rem;

            line-height: 1.2;
            color: $clr_blu;
          }

          .ttl-note {
            font-size: 1.4rem;
            line-height: 1.2;
            margin: 1rem 0 0;
          }

          .circle {
            width: 12rem;
            position: relative;
          }

          .circle-circle {
            @include posi_c_c;
            width: 10rem;
            height: 10rem;
            background: rgba(#fff, .8);
            border-radius: 50%;
          }

          .circle-circle::before,
          .circle-circle::after {
            @include posi_c_c;
            content: "";
            border-radius: 50%;
          }

          .circle-circle::before {
            width: 22rem;
            height: 22rem;
            background: rgba(#fff, .3);
          }

          .circle-circle::after {
            width: 3rem;
            height: 3rem;
            background: $clr_blu;
          }

          .copy {
            width: 78rem;
            margin: 0 0 0 auto;
            position: relative;
            z-index: 2;
          }

          .copy-inner {
            @include posi_t_l;
            top: calc(50% - 1.6rem);
          }

          .copy-inner.other-1 {
            top: -4rem;
          }

          .copy-lead {
            font-size: 2.5rem;

            line-height: 1.2;
            color: $clr_blu;
          }

          .copy-txt {
            font-size: 1.4rem;
            line-height: 1.4;
            margin: 0.6rem 0 0;
          }

          .copy-other-1 {
            width: 32rem;
            margin: 1rem 0 0;
          }

          .copy-other-2 {
            width: 18rem;
            margin: 1rem 0 0;
          }

          .copy-other-3 {
            width: 72rem;
            margin: -3rem 0 0;
          }

          .copy-other-4 {
            width: 63rem;
            margin: 2rem 0 0;

            p {
              font-size: 1.1rem;
              margin: 1rem 0 0;
            }
          }

          .copy-other-5 {
            width: 43rem;
            margin: 20rem 0 0;
          }

          .copy-other-6 {
            width: 18rem;
            margin: 8rem 0 0;
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-qcs {
      padding: 12rem 0 0;
      // background: #E0F0FE;

      .ttl-wrap {
        margin: 0 0 5rem;

        .ttl-box {
          .h2 {
            // position: relative;

            img {
              width: 24rem * 1.5;
            }
          }

          .h2::before {
            // @include posi_c_r;
            // content: "";
            width: calc(100% - 26rem * 1.5);
            // height: 1px;
            // background: #D9D9D9;
          }

          .note {
            font-size: 2.2rem;
            margin: 2rem 0 0;
          }
        }
      }

      .qcs-wrap {
        .label-area {
          .label-box {
            // @include flex_c_c;
            flex-direction: column;
            height: 9rem;
            // background: $clr_blu;
            // color: #fff;

            .h3 {
              // text-align: center;

              span {
                font-size: 2.2rem;
              }
            }
          }

          .label-box.h-8 {
            height: 14rem;

            .note {
              font-size: 2.2rem;
              // line-height: 1.4;
              margin: 1rem 0 0;
            }
          }
        }

        .list-area-outer {
          overflow-x: scroll;
        }

        .list-area {
          width: 120rem;
          padding: 2rem 0rem 13rem;
          // position: relative;
          // overflow: hidden;

          .line-box {
            // @include posi_t_l;
            left: calc(0rem + 30rem + 12rem - 6rem - 1px);
            // width: 2px;
            // height: 100%;
            // background: #fff;
          }

          .list-box {
            // @include flex;
            // position: relative;

            .ttl {
              // @include flex_c_c;
              // flex-direction: column;
              width: 30rem;
              height: 28rem;
              // text-align: center;
              // position: relative;
              // z-index: 2;
            }

            .ttl-ttl {
              font-size: 3.6rem;
              // 
              // line-height: 1.2;
              // color: $clr_blu;
            }

            .ttl-note {
              font-size: 2.4rem;
              // line-height: 1.2;
              margin: 2rem 0 0;
            }

            .circle {
              // width: 12rem;
              // position: relative;
            }

            .circle-circle {
              // @include posi_c_c;
              // width: 10rem;
              // height: 10rem;
              // background: rgba(#fff, .8);
              // border-radius: 50%;
            }

            .circle-circle::before,
            .circle-circle::after {
              // @include posi_c_c;
              // content: "";
              // border-radius: 50%;
            }

            .circle-circle::before {
              // width: 22rem;
              // height: 22rem;
              // background: rgba(#fff, .3);
            }

            .circle-circle::after {
              // width: 3rem;
              // height: 3rem;
              // background: $clr_blu;
            }

            .copy {
              width: 72rem;
              // margin: 0 0 0 auto;
              // position: relative;
              // z-index: 2;
            }

            .copy-inner {
              // @include posi_t_l;
              top: calc(50% - 1.6rem);
            }

            .copy-inner.other-1 {
              top: -6rem;
            }

            .copy-lead {
              font-size: 3.6rem;
              // 
              // line-height: 1.2;
              // color: $clr_blu;
            }

            .copy-txt {
              font-size: 2.6rem;
              // line-height: 1.4;
              margin: 1rem 0 0;
            }

            .copy-other-1 {
              max-width: 72rem;
              width: 32rem * 1.5;
              margin: 2rem 0 0;
            }

            .copy-other-2 {
              max-width: 72rem;
              width: 18rem * 1.5;
              margin: 2rem 0 0;
            }

            .copy-other-3 {
              max-width: 72rem;
              width: 72rem * 1.5;
              margin: -4rem 0 0;
            }

            .copy-other-4 {
              max-width: 72rem;
              width: 63rem * 1.5;
              margin: 3rem 0 0;

              p {
                font-size: 2.2rem;
                margin: 2rem 0 0;
              }
            }

            .copy-other-5 {
              max-width: 72rem;
              width: 43rem * 1.5;
              margin: 26rem 0 0;
            }

            .copy-other-6 {
              max-width: 72rem;
              width: 18rem * 1.5;
              margin: 12rem 0 0;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageService.quality ___end
}