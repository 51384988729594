@use "../global" as *;

#pageTop {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-main {
    height: calc(100vh - 23rem);

    .img-wrap {
      @include posi_t_l;
      width: 100%;
      height: 100%;
      height: 100%;

      .img-box {
        @include posi_cover;

        video {
          @include object_fit;
        }
      }
    }

    .copy-wrap {
      @include posi_b_l;
      bottom: 6rem;
      width: 100%;

      .copy-box {
        @include flex_c_c;
        width: 46rem;
        height: 20rem;
        background: rgba($clr_blu, .8);
        border-radius: 1rem;

        .copy {
          width: 31.7rem;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-main {
      height: calc(100svh - 27rem);

      .img-wrap {
        // @include posi_t_l;
        // width: 100%;
        // height: 100%;

        .img-box {
          // @include posi_cover;

          video {
            // @include object_fit;
          }
        }
      }

      .copy-wrap {
        // @include posi_b_l;
        bottom: 4rem;
        // width: 100%;

        .copy-box {
          // @include flex_c_c;
          // width: 46rem;
          // height: 20rem;
          // background: rgba($clr_blu, .8);
          // border-radius: 1rem;

          .copy {
            width: 31.7rem * 1.25;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-info {
    padding: 4rem 0 6rem;
    background: #f2f5f9;

    .info-wrap {
      @include flex_b;

      .info-area {
        width: 58rem;
        background: #fff;

        .ttl-box {
          @include flex_b_c;
          height: 8rem;
          padding: 0 4rem;
          border-bottom: solid 1px #d9d9d9;

          .ttl {}

          .link {
            @include transition;
            @include flex_s_e;
            gap: 0 0.4rem;

            i {
              @include flex_c_c;
              width: 0.6rem;
              position: relative;
              top: -0.1rem;
            }

            span {
              font-size: 1.3rem;
            }
          }

          .link:hover {
            opacity: .7;
          }
        }

        .list-box {
          padding: 0 4rem 4.4rem;

          .list {
            @include flex_s_c;
            padding: 2rem 0 2rem;
            border-bottom: solid 1px #d9d9d9;
          }

          .list-date {
            @include flex_s_c;
            width: 10rem;
            height: 2.5rem;

            span {
              letter-spacing: 0;
              line-height: 1;
              color: $clr_blu;
            }
          }

          .list-label {
            @include flex_s_s;
            gap: 0.4rem;
            width: calc(100% - 10rem);

            span {
              @include flex_c_c;
              min-width: 11.5rem;
              height: 2.5rem;
              padding: 0 2rem 0.2rem;
              background: $clr_blu;
              border-radius: 100vh;
              font-size: 1.3rem;
              color: #fff;
            }
          }

          .list-txt {
            width: 100%;
            letter-spacing: 0;
            line-height: 1.4;
            margin: 1rem 0 0;

            a {
              @include transition;
            }

            a:hover {
              color: $clr_blu;
            }
          }
        }
      }

      .info-area.news {}

      .info-area.notice {}
    }
  }

  // ______________________________________
  @include mq() {
    .sec-info {
      padding: 5rem 0 7rem;
      // background: #f2f5f9;

      .info-wrap {
        // @include flex_b;
        gap: 4rem 0;

        .info-area {
          width: 100%;
          // background: #fff;

          .ttl-box {
            // @include flex_b_c;
            height: 8rem * 1.5;
            // padding: 0 4rem;
            // border-bottom: solid 1px #d9d9d9;

            .ttl {}

            .link {
              // @include transition;
              @include flex_s_c;
              gap: 0 0.8rem;

              i {
                // @include flex_c_c;
                width: 0.6rem * 1.5;
                // position: relative;
                top: 0rem;
              }

              span {
                font-size: 2.4rem;
              }
            }

            .link:hover {
              // opacity: .7;
            }
          }

          .list-box {
            // padding: 0 4rem 4.4rem;

            .list {
              // @include flex_s_c;
              padding: 3rem 0 3rem;
              // border-bottom: solid 1px #d9d9d9;
            }

            .list-date {
              // @include flex_s_c;
              width: 10rem * 1.5;
              height: 2.5rem * 1.5;

              span {
                // letter-spacing: 0;
                // line-height: 1;
                // color: $clr_blu;
              }
            }

            .list-label {
              // @include flex_s_s;
              gap: 0.8rem;
              width: calc(100% - 10rem * 1.5);

              span {
                // @include flex_c_c;
                min-width: 11.5rem * 1.5;
                height: 2.5rem * 1.5;
                // padding: 0 2rem 0.2rem;
                // background: $clr_blu;
                // border-radius: 100vh;
                font-size: 2.0rem;
                // color: #fff;
              }
            }

            .list-txt {
              // width: 100%;
              // letter-spacing: 0;
              // line-height: 1.4;
              margin: 2rem 0 0;

              a {
                // @include transition;
              }

              a:hover {
                // color: $clr_blu;
              }
            }
          }
        }

        .info-area.news {}

        .info-area.notice {}
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-lists {
    padding: 5rem 0;
    background: $clr_blu;

    .lists-wrap {
      .copy-area {
        .copy-box {
          color: #fff;
          text-align: center;
        }
      }

      .slide-area {
        margin: 5rem 0 0;
        position: relative;

        .swiper {
          overflow: initial;
        }

        .swiper-wrapper {}

        .swiper-slide {
          .list-box {
            display: block;

            .img {
              height: 26rem;
              position: relative;
              overflow: hidden;

              img {
                @include transition;
                @include object_fit;
                will-change: transform;
              }
            }

            .img-label {
              @include posi_t_l;
              @include flex_c_c;
              width: 10rem;
              height: 2.5rem;

              span {
                font-size: 1.3rem;
                color: #fff;
              }
            }

            .img-label.clr-red {
              background: $clr_red;
            }

            .img-label.crl-blu {
              background: $clr_blu2;
            }

            .img-label-2 {
              @include posi_b_l;
              @include flex_c_c;
              width: 10rem;
              height: 2.5rem;
              background: #8e8e8e;

              span {
                font-size: 1.3rem;
                color: #fff;
              }
            }

            .copy {
              @include flex_s_c;
              height: 6rem;
              padding: 0 2rem;
              background: #fff;
              border-radius: 0 0 1rem 1rem;
              position: relative;

              >span {
                font-size: 1.4rem;
              }
            }

            .copy-btn {
              @include posi_c_r;
              right: 2rem;
            }
          }

          .list-box:hover {
            .img {
              img {
                transform: scale(1.05);
              }
            }

            .copy-btn {
              background: $clr_blu;
            }
          }

          .list-box.close {
            .img-close {
              @include posi_cover;
              @include flex_c_c;
              background: rgba(#000, .5);

              p {
                font-size: 2.0rem;
                letter-spacing: 0.1em;
                color: #fff;
              }
            }
          }
        }

        .swiper-btn-prev,
        .swiper-btn-next {
          width: 1.6rem;
          z-index: 2;
          cursor: pointer;
        }

        .swiper-btn-prev {
          @include posi_c_l;
          left: calc(50% - 63rem);
        }

        .swiper-btn-next {
          @include posi_c_r;
          right: calc(50% - 63rem);
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-lists {
      padding: 6rem 0;
      // background: $clr_blu;

      .lists-wrap {
        .copy-area {
          .copy-box {
            // color: #fff;
            // text-align: center;
          }
        }

        .slide-area {
          margin: 6rem 0 0;
          // position: relative;

          .swiper {
            // overflow: initial;
          }

          .swiper-wrapper {}

          .swiper-slide {
            .list-box {
              // display: block;

              .img {
                height: 22rem;
                // position: relative;
                // overflow: hidden;

                img {
                  // @include transition;
                  // @include object_fit;
                  // will-change: transform;
                }
              }

              .img-label {
                // @include posi_t_l;
                // @include flex_c_c;
                width: 17rem;
                height: 4rem;

                span {
                  font-size: 2.2rem;
                  // color: #fff;
                }
              }

              .img-label.clr-red {
                // background: $clr_red;
              }

              .img-label.crl-blu {
                // background: $clr_blu2;
              }

              .img-label-2 {
                // @include posi_b_l;
                // @include flex_c_c;
                width: 17rem;
                height: 4rem;
                // background: #8e8e8e;

                span {
                  font-size: 2.2rem;
                  // color: #fff;
                }
              }

              .copy {
                // @include flex_s_c;
                height: 8rem;
                // padding: 0 2rem;
                // background: #fff;
                // border-radius: 0 0 1rem 1rem;
                // position: relative;

                >span {
                  font-size: 2.4rem;
                }
              }

              .copy-btn {
                // @include posi_c_r;
                // right: 2rem;
                display: none;
              }
            }

            .list-box:hover {
              .img {
                img {
                  // transform: scale(1.05);
                }
              }

              .copy-btn {
                // background: $clr_blu;
              }
            }

            .list-box.close {
              .img-close {
                // @include posi_cover;
                // @include flex_c_c;
                // background: rgba(#000, .5);

                p {
                  font-size: 3.0rem;
                  // letter-spacing: 0.1em;
                  // color: #fff;
                }
              }
            }
          }

          .swiper-btn-prev,
          .swiper-btn-next {
            width: 1.8rem;
            // z-index: 2;
            // cursor: pointer;
          }

          .swiper-btn-prev {
            // @include posi_c_l;
            left: -3rem;
          }

          .swiper-btn-next {
            // @include posi_c_r;
            right: -3rem;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-search {
    background: $clr_blu;

    .search-wrap {
      @include flex_b;

      .link-box {
        @include flex_c_c;
        flex-direction: column;
        width: calc(50% - 0.15rem);
        height: 22rem;
        background: $clr_blu2;
        position: relative;

        .ttl {
          font-weight: 400;
          letter-spacing: 0.2em;
          color: #fff;
        }

        .logo {
          @include flex_c_c;
          width: 21rem;
          height: 10rem;
        }

        .txt {
          letter-spacing: 0.15em;
          color: #fff;
        }

        .btn {
          @include posi_c_r;
          right: 2rem;
        }
      }

      .link-box:hover {
        .btn {
          background: #fff;

          span {
            color: $clr_blu;
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-search {
      // background: $clr_blu;

      .search-wrap {
        // @include flex_b;
        gap: 0.4rem 0;

        .link-box {
          // @include flex_c_c;
          // flex-direction: column;
          width: 100%;
          height: 22rem * 1.5;
          // background: $clr_blu2;
          // position: relative;

          .ttl {
            // font-weight: 400;
            // letter-spacing: 0.2em;
            // color: #fff;
          }

          .logo {
            // @include flex_c_c;
            width: 21rem * 1.5;
            height: 10rem * 1.5;
          }

          .txt {
            // letter-spacing: 0.15em;
            // color: #fff;
          }

          .btn {
            // @include posi_c_r;
            // right: 2rem;
          }
        }

        .link-box:hover {
          .btn {
            // background: #fff;

            span {
              // color: $clr_blu;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-links {
    .links-wrap {
      @include flex;
      background: #f2f5f9;

      .img-area {
        width: 80rem;
        height: 45rem;
        position: relative;

        .img-box {
          @include posi_cover;
        }
      }

      .copy-area {
        width: calc(100% - 80rem);
        position: relative;
        z-index: 1;

        .copy-box {
          width: 40rem;
          margin: 12rem auto 0;

          .ttl {
            display: inline-block;
            padding: 0 0 2rem;
            color: $clr_blu;
            border-bottom: solid 1px $clr_blu;
          }

          .txt {
            letter-spacing: 0;
            margin: 3rem 0 0;
          }

          .btn-outer {
            position: relative;
          }

          .btn {
            @include transition;
            @include flex_c_c;
            width: 100%;
            height: 6.5rem;
            background: #fff;
            margin: 4rem 0 0;
            position: relative;

            span {
              @include transition;
              font-size: 1.6rem;
              font-weight: 900;
              color: $clr_blu;
            }

            i {
              @include transition;
              @include posi_c_r;
              right: 3.6rem;
              width: 2rem;
              height: 2rem;
            }

            i::before,
            i::after {
              @include transition;
              @include posi_c_c;
              content: "";
              background: $clr_blu;
            }

            i::before {
              width: 100%;
              height: 2px;
            }

            i::after {
              width: 2px;
              height: 100%;
            }
          }

          .btn:hover {
            background: $clr_blu;

            span {
              color: #fff;
            }

            i::before,
            i::after {
              background: #fff;
            }
          }

          .btn.act {
            background: $clr_blu;

            span {
              color: #fff;
            }

            i::before,
            i::after {
              background: #fff;
            }

            i::after {
              opacity: 0;
            }
          }

          .links {
            @include transition;
            @include posi_t_l;
            top: 6.5rem;
            width: 100%;
            background: #fff;
            overflow: hidden;

            a {
              @include transition;
              @include flex_s_c;
              gap: 0 1.2rem;
              height: 6.4rem;
              padding: 0 3.6rem 0 1.2rem;
              border-bottom: solid 1px #d3d3d3;

              i:nth-of-type(1) {
                @include flex_c_c;
                width: .6rem;
              }

              i:nth-of-type(2) {
                @include transition;
                font-size: 1.4rem;
                font-weight: 900;
                color: $clr_blu;
                margin: 0 0 0 auto;
              }

              span {
                @include transition;
                font-size: 1.4rem;

                letter-spacing: 0.05em;
              }
            }

            a:hover {
              background: $clr_blu;

              i:nth-of-type(2) {
                color: #fff;
              }

              span {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .links-wrap.reverse {
      flex-direction: row-reverse;
    }
  }

  // ______________________________________
  @include mq() {
    .sec-links {
      .links-wrap {
        // @include flex;
        // background: #f2f5f9;

        .img-area {
          width: 100%;
          // height: 45rem;
          // position: relative;

          .img-box {
            // @include posi_cover;
          }
        }

        .copy-area {
          width: 100%;
          // position: relative;
          // z-index: 1;

          .copy-box {
            width: 100%;
            padding: 0 4rem;
            margin: 8rem auto 10rem;

            .ttl {
              // display: inline-block;
              padding: 0 0 3rem;
              // color: $clr_blu;
              // border-bottom: solid 1px $clr_blu;
            }

            .txt {
              // letter-spacing: 0;
              margin: 4rem 0 0;
            }

            .btn-outer {
              // position: relative;
            }

            .btn {
              // @include transition;
              // @include flex_c_c;
              // width: 100%;
              height: 6.5rem * 1.5;
              // background: #fff;
              margin: 5rem 0 0;
              // position: relative;

              span {
                // @include transition;
                font-size: 2.6rem;
                // font-weight: 900;
                // color: $clr_blu;
              }

              i {
                // @include transition;
                // @include posi_c_r;
                right: 4.6rem;
                width: 3rem;
                height: 3rem;
              }

              i::before,
              i::after {
                // @include transition;
                // @include posi_c_c;
                // content: "";
                // background: $clr_blu;
              }

              i::before {
                // width: 100%;
                // height: 2px;
              }

              i::after {
                // width: 2px;
                // height: 100%;
              }
            }

            .btn:hover {
              background: #fff;

              span {
                color: $clr_blu;
              }

              i::before,
              i::after {
                background: $clr_blu;
              }
            }

            .btn.act {
              background: $clr_blu;

              span {
                color: #fff;
              }

              i::before,
              i::after {
                background: #fff;
              }

              i::after {
                opacity: 0;
              }
            }

            .links {
              // @include transition;
              // @include posi_t_l;
              position: relative;
              top: auto;
              // width: 100%;
              // background: #fff;
              // overflow: hidden;

              a {
                // @include transition;
                // @include flex_s_c;
                gap: 0 2rem;
                height: 6.4rem * 1.5;
                padding: 0 4.6rem 0 2.2rem;
                // border-bottom: solid 1px #d3d3d3;

                i:nth-of-type(1) {
                  // @include flex_c_c;
                  width: .6rem * 1.5;
                }

                i:nth-of-type(2) {
                  // @include transition;
                  font-size: 2.6rem;
                  // font-weight: 900;
                  // color: $clr_blu;
                  // margin: 0 0 0 auto;
                }

                span {
                  // @include transition;
                  font-size: 2.6rem;
                  // 
                  // letter-spacing: 0.05em;
                }
              }

              a:hover {
                // background: $clr_blu;

                i:nth-of-type(2) {
                  // color: #fff;
                }

                span {
                  // color: #fff;
                }
              }
            }
          }
        }
      }

      .links-wrap.reverse {
        // flex-direction: row-reverse;
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-movie {
    padding: 11rem 0 9rem;

    .movie-wrap {
      .copy-box {
        text-align: center;
      }

      .movie-box {
        width: 82rem;
        margin: 3rem auto 0;
        position: relative;

        .youtube {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
        }

        .youtube iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        .note {
          margin: 1rem 0 0;
        }

        .btn {
          @include posi_c_c;
          width: 10rem;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-movie {
      padding: 12rem 0 10rem;

      .movie-wrap {
        .copy-box {
          // text-align: center;
        }

        .movie-box {
          width: 100%;
          margin: 4rem auto 0;
          // position: relative;

          .youtube {
            // position: relative;
            // width: 100%;
            // padding-top: 56.25%;
          }

          .youtube iframe {
            // position: absolute;
            // top: 0;
            // right: 0;
            // width: 100%;
            // height: 100%;
          }

          .note {
            margin: 2rem 0 0;
          }

          .btn {
            // @include posi_c_c;
            width: 10rem * 1.25;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-contact {
    background: $clr_blu;

    .contact-wrap {
      display: block;
      padding: 5rem 0 4rem;

      .copy-box {
        .ttl {
          padding: 0 0 2rem;
          color: #fff;
          text-align: center;
          position: relative;
        }

        .ttl::before {
          @include posi_b_c;
          content: "";
          width: 21.5rem;
          height: 0.1rem;
          background: #fff;
          opacity: .2;
        }
      }

      .btn-box {
        margin: 3rem 0 0;

        .btn {
          margin: 0 auto;
        }
      }
    }

    .contact-wrap:hover {
      .btn-box {
        .btn {
          background: #fff;

          span {
            color: $clr_blu;
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-contact {
      // background: $clr_blu;

      .contact-wrap {
        // display: block;
        padding: 6rem 0 5rem;

        .copy-box {
          .ttl {
            padding: 0 0 3rem;
            // color: #fff;
            // text-align: center;
            // position: relative;
          }

          .ttl::before {
            // @include posi_b_c;
            // content: "";
            width: 21.5rem * 1.5;
            height: 0.1rem * 1.5;
            // background: #fff;
            // opacity: .2;
          }
        }

        .btn-box {
          margin: 4rem 0 0;

          .btn {
            // margin: 0 auto;
          }
        }
      }

      .contact-wrap:hover {
        .btn-box {
          .btn {
            // background: #fff;

            span {
              // color: $clr_blu;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-connection {
    padding: 11rem 0 9rem;

    .connection-wrap {
      .copy-area {
        .copy-box {
          text-align: center;
        }
      }

      .link-area {
        @include flex_b;
        margin: 4rem 0 0;

        .link-box {
          @include transition;
          width: 37rem;

          .img {
            border: solid 1px #d3d3d3;
          }

          .copy {
            @include flex_c_c;
            gap: 0 1.6rem;
            margin: 2rem 0 0;

            span {
              font-size: 1.8rem;

              letter-spacing: 0.05em;
            }

            i {
              font-size: 1.4rem;
              font-weight: 900;
              color: $clr_blu2;
            }
          }
        }

        .link-box:hover {
          opacity: .7;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-connection {
      padding: 12rem 0 10rem;

      .connection-wrap {
        .copy-area {
          .copy-box {
            // text-align: center;
          }
        }

        .link-area {
          @include flex_c;
          gap: 6rem 0;
          margin: 5rem 0 0;

          .link-box {
            // @include transition;
            width: 59rem;

            .img {
              // border: solid 1px #d3d3d3;
            }

            .copy {
              // @include flex_c_c;
              gap: 0 2.6rem;
              margin: 3rem 0 0;

              span {
                font-size: 2.8rem;
                // 
                // letter-spacing: 0.05em;
              }

              i {
                font-size: 2.6rem;
                // font-weight: 900;
                // color: $clr_blu2;
              }
            }
          }

          .link-box:hover {
            // opacity: .7;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageTop ___end
}