@use "../global" as *;

#pageCompany.message {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-message {
    margin: 8rem 0 15rem;

    .main-wrap {
      @include flex_s_c;
      gap: 0 8rem;

      .img-box {
        width: 72rem;
        height: 50rem;
        border-radius: 3rem;
        position: relative;
        overflow: hidden;
      }

      .copy-box {
        .h2 {
          line-height: 1.8;
        }
      }
    }

    .copy-wrap {
      margin: 10rem 0 0;

      .copy-box {}

      .name-box {
        text-align: right;
        margin: 6rem 0 0;

        .name {
          font-size: 2.0rem;
          
          letter-spacing: 0.1em;

          span {
            font-size: 2.8rem;
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-message {
      margin: 9rem 0 16rem;

      .main-wrap {
        // @include flex_s_c;
        // gap: 0 8rem;
        display: block;
        padding: 0 4rem;

        .img-box {
          width: 100%;
          height: 46rem;
          // border-radius: 3rem;
          // position: relative;
          // overflow: hidden;
        }

        .copy-box {
          margin: 5rem 0 0;

          .h2 {
            // line-height: 1.8;
          }
        }
      }

      .copy-wrap {
        margin: 11rem 0 0;

        .copy-box {}

        .name-box {
          // text-align: right;
          margin: 7rem 0 0;

          .name {
            font-size: 3.4rem;
            // 
            // letter-spacing: 0.1em;

            span {
              font-size: 4.4rem;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageCompany.message ___end
}