@use "../global" as *;

#pageSustainability.sdgs {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-copy {
    padding: 8rem 0 10rem;

    .copy-wrap {
      .copy-box {
        text-align: center;
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-copy {
      padding: 9rem 0 11rem;

      .copy-wrap {
        .copy-box {
          // text-align: center;
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-cntnt {
    padding: 8rem 0 12rem;

    .copy-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }

      .txt-box {
        text-align: center;
        margin: 4rem 0 0;
      }

      .table-box {
        border-top: 1px solid #B2B2B2;
        margin: 4rem 0 0;

        .table {
          @include flex;
          border-bottom: 1px solid #B2B2B2;

          p {
            padding: 2rem 0;
          }

          p:nth-of-type(1) {
            width: 37rem;
            padding-left: 2rem;
            background: #F2F2F2;
          }

          p:nth-of-type(2) {
            width: calc(100% - 37rem);
            padding-left: 4rem;
            background: #fff;

            span {
              font-size: 1.1rem;
            }
          }
        }
      }

      .btn-box {
        margin: 4rem 0 0;
      }

      .acc-box {
        @include transition;
        overflow: hidden;

        .ttl {
          text-align: center;
          margin: 4rem 0 0;
        }

        .ttl-h2 {}

        .ttl-date {
          margin: 1rem 0 0;
        }

        .frame {
          margin: 6rem 0 0;
        }

        .frame-2 {
          .com-copy-box-2 {
            text-align: left;
          }
        }

        .frame-ttl {
          text-align: center;
          margin: 0 0 3rem;
        }
      }

      .bnr-box {
        @include transition;
        display: block;
        height: 19rem;
        margin: 3rem 0 0;
        position: relative;
        overflow: hidden;

        .img {
          @include transition;
        }

        .cover {
          @include transition;
          @include posi_cover;
          background: rgba(#000, .5);
        }

        .ttl {
          @include posi_c_l;
          width: 100%;
          color: #fff;
          text-align: center;
        }
      }

      .bnr-box:hover {
        .img {
          transform: scale(1.05);
        }

        .cover {
          background: rgba(#000, .75);
        }
      }

      .copy-box {
        margin: 7rem 0 0;

        .copy {
          @include flex;
          margin: 2rem 0 0;
        }

        .copy-ttl {
          @include flex_c_c;
          width: 30rem;
          height: 8rem;
          border-right: 1px solid #B2B2B2;
        }

        .copy-txt {
          @include flex_s_c;
          width: calc(100% - 30rem);
          padding: 0 0 0 4rem;
        }
      }
    }

    .cntnt-wrap {
      margin: 8rem 0 0;
      position: relative;

      .main-area {
        @include flex_b_s;

        .copy-box {
          width: 70rem;

          .h3 {
            display: inline-block;
            padding: 0 0 1.6rem;
            border-bottom: solid 1px $clr_blu;
            letter-spacing: 0;
            line-height: 1.4;
          }

          .txt {
            margin: 2rem 0 0;
          }

          .links {
            margin: 1rem 0 0;
          }

          .links-link {
            margin: 1.6rem 0 0;
          }

          .sdgs {
            @include transition;
            @include flex_s_c;
            gap: 0 1.6rem;
            padding: 2.4rem 1.6rem 2.4rem 3.2rem;
            background: #F2F7FB;
            border-radius: 1rem;
            margin: 2rem 0 0;

            i:nth-of-type(1) {
              @include flex_c_c;
              width: .6rem;
            }

            i:nth-of-type(2) {
              @include flex_c_c;
              width: 1.6rem;
            }

            span:nth-of-type(1) {
              font-size: 1.4rem;
            }

            span:nth-of-type(2) {
              width: 26rem;
              margin: 0 0 0 auto;
            }
          }

          .sdgs:hover {
            opacity: .7;
          }

          .imgs {
            @include flex_s;
            gap: 0 2rem;
            margin: 2rem 0 0;
          }

          .imgs-list {
            width: 20rem;
            text-align: center;

            span {
              display: block;
              font-size: 1.2rem;
              line-height: 1.4;
              margin: 1rem 0 0;
            }

            i {
              display: inline-block;
              width: 20rem;
            }
          }
        }

        .img-box {
          width: 44rem;
        }
      }

      .copy-area {
        margin: 2rem 0 0;

        .ttl-box {
          text-align: center;
        }

        .copy-box {
          padding: 2rem 8rem;
          background: #fff;
          border: solid 0.8rem rgba($clr_blu, .05);
          margin: 2rem 0 0;

          .txt {
            @include flex_s_c;
            gap: 0 1.6rem;
            padding: 0 0 1rem 1.6rem;
            border-bottom: solid 1px #F2F7FB;
            margin: 2rem 0;

            i {
              width: 1rem;
              height: 1rem;
              background: $clr_blu;
              border-radius: 50%;
            }

            span {
              font-size: 1.4rem;
            }

            sup {
              font-size: 1rem;
              vertical-align: top;
            }
          }

          .note {
            margin: 2rem 0;
          }
        }
      }

      .bnr-area {
        margin: 2rem 0 0;

        .bnr-box {
          @include transition;
          display: block;
          height: 19rem;
          position: relative;
          overflow: hidden;

          .img {
            @include transition;
          }

          .cover {
            @include transition;
            @include posi_cover;
            background: rgba(#000, .5);
          }

          .ttl {
            @include posi_c_l;
            width: 100%;
            color: #fff;
            text-align: center;
          }
        }

        .bnr-box:hover {
          .img {
            transform: scale(1.05);
          }

          .cover {
            background: rgba(#000, .75);
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-cntnt {
      padding: 9rem 0 13rem;

      .copy-wrap {
        // position: relative;

        .ttl-box {
          // text-align: center;
        }

        .txt-box {
          // text-align: center;
          margin: 5rem 0 0;
        }

        .table-box {
          // border-top: 1px solid #B2B2B2;
          margin: 5rem 0 0;

          .table {
            // @include flex;
            // border-bottom: 1px solid #B2B2B2;

            p {
              padding: 3rem 0;
            }

            p:nth-of-type(1) {
              width: 34rem;
              padding-left: 2rem;
              // background: #F2F2F2;
            }

            p:nth-of-type(2) {
              width: calc(100% - 34rem);
              padding-left: 2rem;
              // background: #fff;

              span {
                font-size: 2.2rem;
              }
            }
          }
        }

        .btn-box {
          margin: 5rem 0 0;
        }

        .acc-box {
          // @include transition;
          // overflow: hidden;

          .ttl {
            // text-align: center;
            margin: 5rem 0 0;
          }

          .ttl-h2 {}

          .ttl-date {
            margin: 2rem 0 0;
          }

          .frame {
            margin: 7rem 0 0;
          }

          .frame-2 {
            .com-copy-box-2 {
              // text-align: left;
            }
          }

          .frame-ttl {
            // text-align: center;
            margin: 0 0 4rem;
          }
        }

        .bnr-box {
          // @include transition;
          // display: block;
          // height: 19rem;
          margin: 4rem 0 0;
          // position: relative;
          // overflow: hidden;

          .img {
            // @include transition;
          }

          .cover {
            // @include transition;
            // @include posi_cover;
            // background: rgba(#000, .5);
          }

          .ttl {
            // @include posi_c_l;
            // width: 100%;
            // color: #fff;
            // text-align: center;
          }
        }

        .bnr-box:hover {
          .img {
            // transform: scale(1.05);
          }

          .cover {
            // background: rgba(#000, .75);
          }
        }

        .copy-box {
          margin: 8rem 0 0;

          .copy {
            // @include flex;
            margin: 6rem 0 0;
          }

          .copy-ttl {
            // @include flex_c_c;
            width: 100%;
            height: auto;
            padding: 0 0 2rem;
            border-right: none;
            border-bottom: 1px solid #B2B2B2;
          }

          .copy-txt {
            // @include flex_s_c;
            width: 100%;
            padding: 2rem 0 0;
          }
        }
      }

      .cntnt-wrap {
        margin: 9rem 0 0;
        // position: relative;

        .main-area {
          // @include flex_b_s;

          .copy-box {
            width: 100%;

            .h3 {
              // display: inline-block;
              padding: 0 0 2.6rem;
              // border-bottom: solid 1px $clr_blu;
              // letter-spacing: 0;
              // line-height: 1.4;
            }

            .txt {
              margin: 3rem 0 0;
            }

            .links {
              margin: 2rem 0 0;
            }

            .links-link {
              margin: 2.6rem 0 0;
            }

            .sdgs {
              // @include transition;
              @include flex_b_c;
              gap: 0 0rem;
              padding: 4rem 5rem;
              // background: #F2F7FB;
              // border-radius: 1rem;
              margin: 3rem 0 0;

              i:nth-of-type(1) {
                // @include flex_c_c;
                width: .6rem * 1.5;
              }

              i:nth-of-type(2) {
                // @include flex_c_c;
                width: 1.6rem * 1.5;
              }

              span:nth-of-type(1) {
                font-size: 2.6rem;
              }

              span:nth-of-type(2) {
                width: 100%;
                margin: 3rem 0 0;
              }
            }

            .sdgs:hover {
              opacity: 1;
            }

            .imgs {
              @include flex_b;
              gap: 0 0rem;
              margin: 3rem 0 0;
            }

            .imgs-list {
              width: 32rem;
              // text-align: center;

              span {
                // display: block;
                font-size: 2.2rem;
                // line-height: 1.4;
                margin: 2rem 0 0;
              }

              i {
                // display: inline-block;
                width: 20rem * 1.75;
              }
            }
          }

          .img-box {
            width: 100%;
            margin: 6rem 0 0;
          }
        }

        .copy-area {
          margin: 3rem 0 0;

          .ttl-box {
            // text-align: center;
          }

          .copy-box {
            padding: 2rem 3rem;
            // background: #fff;
            // border: solid 0.8rem rgba($clr_blu, .05);
            margin: 3rem 0 0;

            .txt {
              // @include flex_s_c;
              gap: 0 2rem;
              padding: 0 0 2rem 0rem;
              // border-bottom: solid 1px #F2F7FB;
              margin: 3rem 0;

              i {
                width: 2rem;
                height: 2rem;
                // background: $clr_blu;
                // border-radius: 50%;
              }

              span {
                width: calc(100% - 2rem - 2rem);
                font-size: 2.6rem;
                line-height: 1.4;
              }

              sup {
                font-size: 2rem;
                // vertical-align: top;
              }
            }

            .note {
              margin: 3rem 0;
            }
          }
        }

        .bnr-area {
          margin: 3rem 0 0;

          .bnr-box {
            // @include transition;
            // display: block;
            // height: 19rem;
            // position: relative;
            // overflow: hidden;

            .img {
              // @include transition;
            }

            .cover {
              // @include transition;
              // @include posi_cover;
              // background: rgba(#000, .5);
            }

            .ttl {
              // @include posi_c_l;
              // width: 100%;
              line-height: 1.4;
              // color: #fff;
              // text-align: center;
            }
          }

          .bnr-box:hover {
            .img {
              // transform: scale(1.05);
            }

            .cover {
              // background: rgba(#000, .75);
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageSustainability.sdgs ___end
}