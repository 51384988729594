@use "../global" as *;

#pageCompany.philosophy {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-jrwp {
    margin: 8rem 0 0;

    .ttl-wrap {
      .ttl-box {
        text-align: center;

        .h2 {
          display: inline-block;
          width: 65.5rem;
        }
      }
    }

    .jrwp-wrap {
      padding: 7rem 0 0;
      margin: 6rem 0 0;
      position: relative;

      .jrwp-area {
        margin: 0 0 9rem;
        position: relative;

        .ttl-box {
          text-align: center;
        }

        .copy-box {
          @include flex_c_c;
          width: 91rem;
          height: 12rem;
          background: #fff;
          text-align: center;
          margin: 3rem auto 0;

          .copy {}
        }

        .copy-box-2 {
          margin-top: 3rem;
        }

        .figure-box {}
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-jrwp {
      margin: 9rem 0 0;

      .ttl-wrap {
        .ttl-box {
          // text-align: center;

          .h2 {
            // display: inline-block;
            // width: 65.5rem;
          }
        }
      }

      .jrwp-wrap {
        padding: 8rem 0 0;
        margin: 7rem 0 0;
        // position: relative;

        .jrwp-area {
          margin: 0 0 10rem;
          // position: relative;

          .ttl-box {
            // text-align: center;
          }

          .copy-box {
            // @include flex_c_c;
            width: 100%;
            height: 12rem * 1.5;
            // background: #fff;
            // text-align: center;
            margin: 4rem auto 0;

            .copy {}
          }

          .copy-box-2 {
            margin-top: 4rem;
          }

          .figure-box {
            position: relative;
            overflow-x: scroll;
            overflow-y: hidden;

            .img {
              width: 150rem;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-westjr {
    padding: 7rem 0 0;
    margin: 17rem 0 15rem;

    .ttl-wrap {
      position: relative;

      .ttl-box {
        text-align: center;

        .h2 {
          display: inline-block;
          width: 18rem;
        }
      }
    }

    .westjr-wrap {
      margin: 6rem 0 0;
      position: relative;

      .westjr-area {
        position: relative;

        .ttl-box {
          text-align: center;
        }

        .copy-box {
          @include flex_c_c;
          width: 91rem;
          height: 18rem;
          background: #fff;
          text-align: center;
          margin: 3rem auto 0;

          .copy {
            line-height: 1.8;
          }
        }

        .copy-box-2 {
          margin-top: 5rem;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-westjr {
      padding: 8rem 0 0;
      margin: 18rem 0 16rem;

      .ttl-wrap {
        .ttl-box {
          // text-align: center;

          .h2 {
            // display: inline-block;
            // width: 18rem;
          }
        }
      }

      .westjr-wrap {
        margin: 7rem 0 0;
        // position: relative;

        .westjr-area {
          // position: relative;

          .ttl-box {
            // text-align: center;
          }

          .copy-box {
            // @include flex_c_c;
            width: 100%;
            height: 34rem;
            // background: #fff;
            // text-align: center;
            margin: 4rem auto 0;

            .copy {
              // line-height: 1.8;
            }
          }

          .copy-box-2 {
            margin-top: 6rem;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageCompany.philosophy ___end
}