@use "../global" as *;

#pageCompany.location {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-anker {
    padding: 8rem 0 0;

    .ank-wrap {
      .ank-area {

        .ank-box:nth-of-type(1),
        .ank-box:nth-of-type(2),
        .ank-box:nth-of-type(3),
        .ank-box:nth-of-type(4) {
          width: calc((100% - 3px) / 4);
        }

        .ank-box:nth-of-type(5),
        .ank-box:nth-of-type(6) {
          width: calc((100% - 1px) / 2);
          background: $clr_blu2;
        }

        .ank-box:nth-of-type(1) {
          border-radius: 1rem 0 0 0;
        }

        .ank-box:nth-of-type(2) {}

        .ank-box:nth-of-type(3) {}

        .ank-box:nth-of-type(4) {
          border-radius: 0 1rem 0 0;
        }

        .ank-box:nth-of-type(5) {
          border-radius: 0 0 0 1rem;
        }

        .ank-box:nth-of-type(6) {
          border-radius: 0 0 1rem 0;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-anker {
      padding: 9rem 0 0;

      .ank-wrap {
        .ank-area {

          .ank-box:nth-of-type(1),
          .ank-box:nth-of-type(2),
          .ank-box:nth-of-type(3),
          .ank-box:nth-of-type(4) {
            width: calc((100% - 1px) / 2);
          }

          .ank-box:nth-of-type(5),
          .ank-box:nth-of-type(6) {
            width: 100%;
            // background: $clr_blu2;
          }

          .ank-box:nth-of-type(1) {
            border-radius: 1rem 0 0 0;
          }

          .ank-box:nth-of-type(2) {
            border-radius: 0 1rem 0 0;
          }

          .ank-box:nth-of-type(3) {}

          .ank-box:nth-of-type(4) {
            border-radius: 0 0rem 0 0;
          }

          .ank-box:nth-of-type(5) {
            border-radius: 0 0 0 0;
          }

          .ank-box:nth-of-type(6) {
            border-radius: 0 0 1rem 1rem;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-loc {
    margin: 9em 0 15rem;

    .ank-wrap {
      position: relative;
      top: -11rem;
    }

    .loc-wrap {
      @include flex_b;
      padding: 6rem 0;
      border-bottom: solid 1px #c9c9c9;

      .data-box {
        width: 52rem;

        .h3 {
          display: inline-block;
          padding: 0 0 2rem;
          border-bottom: solid 1px $clr_blu;
          margin: 0 0 3rem;
        }

        .data {
          @include flex;
          padding: 1rem 0;
          border-bottom: dashed 1px #c9c9c9;

          span:nth-of-type(1) {
            width: 10rem;
            
          }

          span:nth-of-type(2) {
            width: calc(100% - 10rem);
          }
        }

        .txt {
          letter-spacing: 0;
          margin: 2rem 0 0;

          span {
            display: block;
            padding-left: 1em;
            text-indent: -1em;
          }
        }
      }

      .map-box {
        width: 60rem;
        height: 41rem;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    .loc-wrap.first {
      border-top: solid 1px #c9c9c9;
    }
  }

  // ______________________________________
  @include mq() {
    .sec-loc {
      margin: 10em 0 16rem;

      .ank-wrap {
        // position: relative;
        top: -10rem;
      }

      .loc-wrap {
        // @include flex_b;
        padding: 7rem 0;
        // border-bottom: solid 1px #c9c9c9;

        .data-box {
          width: 100%;

          .h3 {
            // display: inline-block;
            padding: 0 0 3rem;
            // border-bottom: solid 1px $clr_blu;
            margin: 0 0 4rem;
          }

          .data {
            // @include flex;
            padding: 2rem 0;
            // border-bottom: dashed 1px #c9c9c9;

            span:nth-of-type(1) {
              width: 14rem;
              // 
            }

            span:nth-of-type(2) {
              width: calc(100% - 14rem);
            }
          }

          .txt {
            // letter-spacing: 0;
            margin: 3rem 0 0;

            span {
              // display: block;
              // padding-left: 1em;
              // text-indent: -1em;
            }
          }
        }

        .map-box {
          width: 100%;
          height: 50rem;
          margin: 5rem 0 0;

          iframe {
            // width: 100%;
            // height: 100%;
          }
        }
      }

      .loc-wrap.first {
        // border-top: solid 1px #c9c9c9;
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageCompany.location ___end
}