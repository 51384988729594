@use "../global" as *;

#pageService.business-investment {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-tab {
    padding: 8rem 0 8rem;

    .copy-wrap {
      .copy-box {
        text-align: center;
      }
    }

    .tab-wrap {
      margin: 8rem 0 0;
    }
  }

  // ______________________________________
  @include mq() {
    .sec-tab {
      padding: 9rem 0 9rem;

      .copy-wrap {
        .copy-box {
          // text-align: center;
        }
      }

      .tab-wrap {
        margin: 9rem 0 0;
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-lists {
    padding: 7rem 0 7rem;

    .copy-wrap {
      position: relative;

      .copy-box {
        text-align: center;
      }
    }

    .list-wrap {
      @include flex_b;
      gap: 5rem 0;
      margin: 7rem 0 0;
      position: relative;

      .list-area {
        width: 57.7rem;
        padding: 4.8rem;
        background: #fff;
        border-radius: 1rem;
        border: 1px solid #EBEBEB;

        .copy-box {
          text-align: center;

          .ttl {
            font-size: 2rem;
            line-height: 1.6;

            span {
              font-size: 2.8rem;
            }
          }

          .txt {
            letter-spacing: 0;
            line-height: 1.6;
            margin: 2rem 0 0;
          }
        }

        .slide-box {
          margin: 2rem 0 0;
        }

        .btn-box {
          margin: 4rem 0 0;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-lists {
      padding: 8rem 0 8rem;

      .copy-wrap {
        // position: relative;

        .copy-box {
          // text-align: center;
        }
      }

      .list-wrap {
        // @include flex_b;
        gap: 6rem 0;
        margin: 8rem 0 0;
        // position: relative;

        .list-area {
          width: 100%;
          padding: 5rem 4rem;
          // background: #fff;
          // border-radius: 1rem;
          // border: 1px solid #EBEBEB;

          .copy-box {
            // text-align: center;

            .ttl {
              font-size: 3.2rem;
              // line-height: 1.6;

              span {
                font-size: 4.0rem;
              }
            }

            .txt {
              // letter-spacing: 0;
              // line-height: 1.6;
              margin: 3rem 0 0;
            }
          }

          .slide-box {
            margin: 3rem 0 0;
          }

          .btn-box {
            margin: 5rem 0 0;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-links {
    padding: 7rem 0;

    .links-wrap {
      @include flex_b;
      position: relative;

      .link-box {
        @include transition;
        width: 50%;
        height: 11rem;
        background: $clr_blu;
        position: relative;
        overflow: hidden;

        .bg {
          @include transition;
        }

        .cover {
          @include transition;
          @include posi_cover;
          background: rgba(#000, .5);
        }

        .copy {
          @include posi_c_l;
          width: 100%;
          line-height: 1.4;
          color: #fff;
          text-align: center;
        }
      }

      .link-box.border::before {
        content: "";
        @include posi_t_l;
        width: 1px;
        height: 100%;
        background: #fff;
      }

      .link-box:hover {
        background: $clr_blu2;

        .bg {
          transform: scale(1.05);
        }

        .cover {
          background: rgba(#000, .75);
        }
      }
    }
  }

  .sec-links.num-1 {}

  .sec-links.num-2 {}

  // ______________________________________
  @include mq() {
    .sec-links {
      padding: 8rem 0;

      .links-wrap {
        // @include flex_b;
        padding: 0 4rem;
        // position: relative;

        .link-box {
          width: 100%;
          height: 15rem;
          // background: $clr_blu;
          // position: relative;
          // overflow: hidden;

          .bg {
            // @include transition;
          }

          .cover {
            // @include transition;
            // @include posi_cover;
            // background: rgba(#000, .5);
          }

          .copy {
            // @include posi_c_l;
            // width: 100%;
            line-height: 1.2;
            // color: #fff;
            // text-align: center;
          }
        }

        .link-box.border::before {
          // content: "";
          // @include posi_t_l;
          width: 100%;
          height: 1px;
          // background: #fff;
        }

        .link-box:hover {
          .bg {
            // transform: scale(1.05);
          }

          .cover {
            // background: rgba(#000, .75);
          }
        }
      }
    }

    .sec-links.num-1 {}

    .sec-links.num-2 {}
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-flow {
    padding: 7rem 0 8rem;
    background: #e0f0fe;

    .flow-wrap {
      .copy-box {
        text-align: center;
      }

      .figure-box {
        margin: 5rem 0 0;
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-flow {
      padding: 8rem 0 9rem;
      // background: $clr_blu;

      .flow-wrap {
        .copy-box {
          // color: #fff;
          // text-align: center;
        }

        .figure-box {
          margin: 6rem 0 0;
          position: relative;
          overflow-x: scroll;
          overflow-y: hidden;

          .figure {
            width: 150rem;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-needs {
    padding: 12rem 0 5rem;

    .copy-wrap {
      .copy-box {
        text-align: center;
      }
    }

    .needs-wrap {
      @include flex_b_s;
      padding: 7rem 0;
      border-top: 1px solid #C9C9C9;

      .main-area {
        width: 57.6rem;

        .img-box {
          height: 34rem;
          border-radius: 1rem;
          position: relative;
          overflow: hidden;
        }
      }

      .copy-area {
        width: 57.6rem;

        .ttl-box {
          .ttl {
            display: inline-block;
            padding: 0 0 1.6rem;
            border-bottom: 1px solid $clr_blu;
          }
        }

        .data-box {
          margin: 2.4rem 0 0;

          .data {
            @include flex;
            padding: 1.6rem 0;
            border-bottom: 1px dashed #C9C9C9;
          }

          .data-ttl {
            width: 18rem;

            span {

              letter-spacing: 0;
              line-height: 1.6;
            }
          }

          .data-txt {
            width: calc(100% - 18rem);

            span {
              display: block;
              padding-left: 1em;
              text-indent: -1em;
              letter-spacing: 0;
              line-height: 1.6;
            }
          }
        }
      }

      .img-area {
        width: 57.6rem;
        margin: 2rem 0 0;

        .img-box {
          height: 30rem;
          border-radius: 1rem;
          position: relative;
          overflow: hidden;
        }
      }
    }

    .needs-wrap.first {
      border-top: none;
    }
  }

  // ______________________________________
  @include mq() {
    .sec-needs {
      padding: 13rem 0 6rem;

      .copy-wrap {
        .copy-box {
          // text-align: center;

          .h2 {
            letter-spacing: 0;
            line-height: 1.6;
          }
        }
      }

      .needs-wrap {
        // @include flex_b_s;
        padding: 8rem 0;
        // border-top: 1px solid #C9C9C9;

        .main-area {
          width: 100%;

          .img-box {
            height: 38rem;
            // border-radius: 1rem;
            // position: relative;
            // overflow: hidden;
          }
        }

        .copy-area {
          width: 100%;
          margin: 3rem 0 0;

          .ttl-box {
            text-align: center;

            .ttl {
              display: block;
              padding: 0 0 2.6rem;
              // border-bottom: 1px solid $clr_blu;
              letter-spacing: 0;
              line-height: 1.4;
            }
          }

          .data-box {
            margin: 3.4rem 0 0;

            .data {
              // @include flex;
              padding: 2.6rem 0;
              // border-bottom: 1px dashed #C9C9C9;
            }

            .data-ttl {
              width: 20rem;

              span {
                // 
                // letter-spacing: 0;
                // line-height: 1.6;
              }
            }

            .data-txt {
              width: calc(100% - 20rem);

              span {
                // display: block;
                // padding-left: 1em;
                // text-indent: -1em;
                // letter-spacing: 0;
                // line-height: 1.6;
              }
            }
          }
        }

        .img-area {
          width: 32rem;
          margin: 3rem 0 0;

          .img-box {
            height: 26rem;
            // border-radius: 1rem;
            // position: relative;
            // overflow: hidden;
          }
        }
      }

      .needs-wrap.first {
        // border-top: none;
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-environment {
    padding: 8rem 0;

    .copy-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }

      .copy-box {
        margin-top: 7rem;
      }
    }

    .table-wrap {
      margin: 6rem 0 0;
      position: relative;

      .table-box {
        table {
          width: 100%;
          border-top: 1px solid #d9d9d9;
          border-right: 1px solid #d9d9d9;
        }

        tr {
          @include flex_b;
          border-bottom: 1px solid #d9d9d9;
        }

        th,
        td {
          border-left: 1px solid #d9d9d9;

          p {
            padding: 1.2rem 0;
            border-bottom: 1px solid #d9d9d9;
            font-size: 1.4rem;
            font-weight: 400;
            letter-spacing: 0.1em;
            line-height: 1.8;
            text-align: center;
          }

          p:last-of-type {
            border-bottom: none;
          }
        }

        th:nth-of-type(1),
        td:nth-of-type(1) {
          @include flex_c_c;
          width: 30rem;
        }

        th:nth-of-type(2),
        td:nth-of-type(2) {
          width: 54rem;
        }

        th:nth-of-type(3),
        td:nth-of-type(3) {
          width: calc(100% - 30rem - 54rem);
        }

        th {
          background: #f2f2f2;
        }

        td {}
      }

      .note-box {
        margin: 4rem 0 0;
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-environment {
      padding: 9rem 0;

      .copy-wrap {
        // position: relative;

        .ttl-box {
          line-height: 1.6;
          // text-align: center;
        }

        .copy-box {
          margin-top: 8rem;

          .copy {
            letter-spacing: 0;
          }
        }
      }

      .table-wrap {
        margin: 7rem 0 0;
        // position: relative;

        .table-box {
          overflow-x: scroll;
          overflow-y: hidden;

          table {
            width: 102rem;
            // border-top: 1px solid #d9d9d9;
            // border-right: 1px solid #d9d9d9;
          }

          tr {
            // @include flex_b;
            // border-bottom: 1px solid #d9d9d9;
          }

          th,
          td {
            // border-left: 1px solid #d9d9d9;

            p {
              padding: 2.2rem 0;
              // border-bottom: 1px solid #d9d9d9;
              font-size: 2.6rem;
              // font-weight: 400;
              letter-spacing: 0em;
              // line-height: 1.8;
              // text-align: center;
            }

            p:last-of-type {
              // border-bottom: none;
            }
          }

          th:nth-of-type(1),
          td:nth-of-type(1) {
            // @include flex_c_c;
            width: 23rem;
          }

          th:nth-of-type(2),
          td:nth-of-type(2) {
            width: 41rem;
          }

          th:nth-of-type(3),
          td:nth-of-type(3) {
            width: calc(100% - 23rem - 41rem);
          }

          th {
            // background: #f2f2f2;
          }

          td {}
        }

        .note-box {
          margin: 5rem 0 0;
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageService.business-investment ___end
}