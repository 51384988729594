@use "../global" as *;

// ______________________________________
// ______________________________________
// ______________________________________
header.header {
  @include posi_t_l;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 11rem;
  background: #fff;

  .header-wrap {
    @include flex_b_c;
    height: 7.5rem;
    padding: 0 8rem;

    .left-box {
      width: 30rem;

      .logo {
        display: block;
      }
    }

    .right-box {
      width: 14rem;

      .logo {
        display: block;
      }
    }
  }

  .links-wrap {}
}

// ______________________________________
@include mq() {
  header.header {
    // @include posi_t_l;
    // position: fixed;
    // z-index: 9999;
    // width: 100%;
    height: 10rem;
    // background: #fff;

    .header-wrap {
      // @include flex_b_c;
      height: 100%;
      padding: 0 13rem 0 2rem;

      .left-box {
        width: 30rem * 1.3;

        .logo {
          // display: block;
        }
      }

      .right-box {
        width: 14rem * 1.3;

        .logo {
          // display: block;
        }
      }
    }

    .links-wrap {}
  }
}