@use "../global" as *;

#pageService.results-lease {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-tab {
    padding: 8rem 0 8rem;

    .tab-wrap {}
  }

  // ______________________________________
  @include mq() {
    .sec-tab {
      padding: 9rem 0 9rem;

      .tab-wrap {}
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-lease {
    padding: 8rem 0 15rem;

    .tab-wrap {
      background: #fff;
      position: relative;
      z-index: 10;

      .tab-area-1 {
        @include flex;
        gap: 0 3px;

        .tab-box {
          @include transition;
          @include flex_c_c;
          gap: 0 1rem;
          width: calc((100% - (3px * 3)) / 4);
          height: 5rem;
          background: #e0f0fe;
          position: relative;

          i {
            @include transition;
            transition-duration: 400ms;
            @include flex_c_c;
            width: .6rem;

            path {
              @include transition;
            }
          }

          span {
            @include transition;
            font-size: 1.4rem;
            letter-spacing: 0.1em;
            color: $clr_blu;
          }
        }

        .tab-box:nth-of-type(1) {
          border-radius: 1rem 0 0 0;
        }

        .tab-box:nth-of-type(4) {
          border-radius: 0 1rem 0 0;
        }

        .tab-box::before {
          @include transition;
          @include posi_b_l;
          bottom: -3px;
          content: "";
          width: 100%;
          height: 3px;
          background: #e0f0fe;
          opacity: 0;
        }

        .tab-box.act {
          background: $clr_blu;

          i {
            transform: rotate(90deg);

            path {
              fill: #fff;
            }
          }

          span {
            color: #fff;
          }
        }

        .tab-box.act::before {
          background: $clr_blu;
          opacity: 1;
        }
      }

      .tab-area-2 {
        @include flex_b;
        padding: 2rem 5rem;
        background: $clr_blu;
        border-radius: 0 0 1rem 1rem;
        margin: 3px 0 0;

        .tab-box {
          @include transition;
          @include flex_c_c;
          width: 20rem;
          height: 3rem;
          border: solid 1px #afafaf;
          border-radius: 100vh;

          span {
            @include transition;
            font-size: 1.4rem;

            letter-spacing: 0.1em;
            color: #fff;
          }
        }

        .tab-box.act {
          background: #fff;

          span {
            color: $clr_blu;
          }
        }

        .tab-box.dis {
          opacity: .4;
          pointer-events: none;
        }
      }
    }

    .lease-wraper-outer {
      margin: 8rem 0 0;
      position: relative;
    }

    .lease-wraper {
      position: relative;
    }

    .lease-wraper-inner {
      // display: none; jsで指定
    }

    .lease-wraper.a-0.b-0 .lease-wraper-inner.a-0.b-0,
    .lease-wraper.a-0.b-1 .lease-wraper-inner.a-0.b-1,
    .lease-wraper.a-0.b-2 .lease-wraper-inner.a-0.b-2,
    .lease-wraper.a-0.b-3 .lease-wraper-inner.a-0.b-3,
    .lease-wraper.a-0.b-4 .lease-wraper-inner.a-0.b-4,
    .lease-wraper.a-1.b-0 .lease-wraper-inner.a-1.b-0,
    .lease-wraper.a-1.b-1 .lease-wraper-inner.a-1.b-1,
    .lease-wraper.a-1.b-2 .lease-wraper-inner.a-1.b-2,
    .lease-wraper.a-1.b-3 .lease-wraper-inner.a-1.b-3,
    .lease-wraper.a-1.b-4 .lease-wraper-inner.a-1.b-4,
    .lease-wraper.a-2.b-0 .lease-wraper-inner.a-2.b-0,
    .lease-wraper.a-2.b-1 .lease-wraper-inner.a-2.b-1,
    .lease-wraper.a-2.b-2 .lease-wraper-inner.a-2.b-2,
    .lease-wraper.a-2.b-3 .lease-wraper-inner.a-2.b-3,
    .lease-wraper.a-2.b-4 .lease-wraper-inner.a-2.b-4,
    .lease-wraper.a-3.b-0 .lease-wraper-inner.a-3.b-0,
    .lease-wraper.a-3.b-1 .lease-wraper-inner.a-3.b-1,
    .lease-wraper.a-3.b-2 .lease-wraper-inner.a-3.b-2,
    .lease-wraper.a-3.b-3 .lease-wraper-inner.a-3.b-3,
    .lease-wraper.a-3.b-4 .lease-wraper-inner.a-3.b-4 {
      display: block !important;
    }

    .copy-wrap {
      padding: 8rem 0 0;

      .copy-box {
        line-height: 1.4;
        text-align: center;
      }
    }

    .list-wrap {
      @include flex_b;
      gap: 4rem 0;
      padding: 7rem 0 0;

      .list-area {
        @include flex;
        flex-direction: row-reverse;
        width: 58rem;
        background: #FFF;
        border: 1px solid #EBEBEB;
        border-radius: 1rem;
        position: relative;
        overflow: hidden;

        .img-box {
          width: 50%;
          position: relative;

          .img {
            @include posi_cover;
            background: #e0f0fe;

            img {
              @include object_fit;
            }
          }

          .img.contain {
            img {
              object-fit: contain;
            }
          }
        }

        .data-box {
          width: 50%;
          padding: 2rem 1rem 7rem;

          .labels {
            @include flex;

            span {
              @include flex_c_c;
              height: 2.4rem;
              font-size: 1.4rem;
            }

            span:nth-of-type(1) {
              width: 6rem;
              background: $clr_blu;
              color: #fff;
            }

            span:nth-of-type(2) {
              width: 8rem;
              background: #fff;
            }
          }

          .ttl {
            font-size: 1.8rem;
            margin: 1.2rem 0 0;
          }

          .data {
            margin: 1.2rem 0 0;
          }

          .data-txt {
            @include flex;
            margin: 0.4rem 0 0;

            span {
              font-size: 1.4rem;
              line-height: 1.4;
            }

            span:nth-of-type(1) {
              width: 10rem;
            }

            span:nth-of-type(2) {
              width: calc(100% - 10rem);
            }
          }

          .label {
            display: inline-block;
            padding: 0.6rem 2rem 0.8rem;
            background: $clr_grn;
            border-radius: 100vh;
            font-size: 1.4rem;
            color: #fff;
            margin: 2rem 0 0;
          }
        }

        .btn-box {
          @include posi_b_l;
          padding: 0 1rem 2rem;

          .btn {}

          .btn.other {
            gap: 0 0.6rem;
            padding: 0 1rem;
          }
        }

        .cs-box {
          .cs {
            @include posi_c_l;
            width: 100%;
            font-size: 1.4rem;
            color: $clr_blu;
            text-align: center;
          }
        }
      }
    }

    .none-wrap {
      padding: 7rem 0 0;

      .note-box {
        text-align: center;
      }
    }

    .description-wrap {
      padding: 7rem 0 0;

      .description-box {
        text-align: center;
      }
    }

    .table-wrap {
      padding: 7rem 0 0;

      .btn-box {
        @include flex_c_c;
        width: 100%;
        height: 10rem;
        border-top: 1px solid #B2B2B2;
        border-bottom: 1px solid #B2B2B2;
        position: relative;

        span {}

        i {
          @include transition;
          @include posi_c_r;
          right: 8rem;
          width: 2rem;
          height: 2rem;
        }

        i::before,
        i::after {
          @include transition;
          @include posi_c_c;
          content: "";
          background: $clr_blu;
        }

        i::before {
          width: 100%;
          height: 2px;
        }

        i::after {
          width: 2px;
          height: 100%;
        }
      }

      .btn-box.act {
        i::after {
          opacity: 0;
        }
      }

      .table-box {
        @include transition;
        overflow: hidden;

        table {
          width: 100%;
          border-right: 1px solid #d9d9d9;
        }

        tr {
          @include flex_b;
          border-bottom: 1px solid #d9d9d9;
        }

        th,
        td {
          @include flex_c_c;
          padding: 1.2rem 0;
          border-left: 1px solid #d9d9d9;
          font-weight: 400;

          p {
            font-size: 1.4rem;
            line-height: 1.6;

            span {}

            span.label {
              display: inline-block;
              padding: 0 1rem;
              border-radius: 100vh;
              background: $clr_grn;
              font-weight: 400;
              color: #fff;
              margin: 0.4rem 0;
            }
          }

          a {
            @include transition;
            @include flex_s_c;
            gap: 0 1.6rem;
            margin: 0.4rem 0;

            span {
              padding: 0.4rem 0;
              font-size: 1.4rem;
              line-height: 1;
              color: $clr_blu;
              border-bottom: solid 1px $clr_blu;
            }

            i {
              @include flex_c_c;
              width: 1.6rem;
            }
          }

          a:hover {
            opacity: .7;
          }
        }

        th:nth-of-type(1),
        td:nth-of-type(1) {
          width: 54rem;
        }

        th:nth-of-type(2),
        td:nth-of-type(2) {
          width: 15rem;
        }

        th:nth-of-type(3),
        td:nth-of-type(3) {
          width: 15rem;
        }

        th:nth-of-type(4),
        td:nth-of-type(4) {
          width: calc(100% - 54rem - 15rem - 15rem);
        }

        th {
          background: #f2f2f2;

        }

        td {}

        td:nth-of-type(1),
        td:nth-of-type(4) {
          @include flex_s_c;
          padding-left: 2.4rem;
        }

        td:nth-of-type(2),
        td:nth-of-type(3) {
          text-align: center;
        }
      }

      .table-box.bt {
        border-top: 1px solid #d9d9d9;
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-lease {
      padding: 9rem 0 16rem;

      .tab-wrap {
        // background: #fff;
        // position: relative;
        // z-index: 10;

        .com-content {
          padding: 0 2rem;
        }

        .tab-area-1 {
          // @include flex;
          gap: 0 1px;

          .tab-box {
            // @include transition;
            // @include flex_c_c;
            // gap: 0 1rem;
            width: calc((100% - (1px * 3)) / 4);
            height: 7rem;
            // background: #e0f0fe;
            // position: relative;

            i {
              // @include transition;
              // transition-duration: 400ms;
              // @include flex_c_c;
              // width: .6rem;
              display: none;

              path {
                // @include transition;
              }
            }

            span {
              // @include transition;
              font-size: 2.0rem;
              // 
              letter-spacing: 0em;
              // color: $clr_blu;
            }
          }

          .tab-box:nth-of-type(1) {
            // border-radius: 1rem 0 0 0;
          }

          .tab-box:nth-of-type(4) {
            // border-radius: 0 1rem 0 0;
          }

          .tab-box::before {
            // @include transition;
            // @include posi_b_l;
            bottom: -1px;
            // content: "";
            // width: 100%;
            height: 1px;
            // background: #e0f0fe;
            // opacity: 0;
          }

          .tab-box.act {
            // background: $clr_blu;

            i {
              // transform: rotate(90deg);

              path {
                // fill: #fff;
              }
            }

            span {
              // color: #fff;
            }
          }

          .tab-box.act::before {
            // background: $clr_blu;
            // opacity: 1;
          }
        }

        .tab-area-2 {
          @include flex_s_s;
          gap: 2rem 2rem;
          padding: 3rem 2rem;
          // background: $clr_blu;
          // border-radius: 0 0 1rem 1rem;
          margin: 1px 0 0;

          .tab-box {
            // @include transition;
            // @include flex_c_c;
            width: 21rem;
            height: 5rem;
            // border: solid 1px #afafaf;
            // border-radius: 100vh;

            span {
              // @include transition;
              font-size: 2.0rem;
              // 
              letter-spacing: 0em;
              // color: #fff;
            }
          }

          .tab-box.act {
            // background: #fff;

            span {
              // color: $clr_blu;
            }
          }

          .tab-box.dis {
            // opacity: .4;
            // pointer-events: none;
          }
        }
      }

      .lease-wraper-outer {
        // position: relative;
      }

      .lease-wraper {
        // position: relative;
      }

      .lease-wraper-inner {
        // display: none; jsで指定
      }

      .lease-wraper.a-0.b-0 .lease-wraper-inner.a-0.b-0,
      .lease-wraper.a-0.b-1 .lease-wraper-inner.a-0.b-1,
      .lease-wraper.a-0.b-2 .lease-wraper-inner.a-0.b-2,
      .lease-wraper.a-0.b-3 .lease-wraper-inner.a-0.b-3,
      .lease-wraper.a-0.b-4 .lease-wraper-inner.a-0.b-4,
      .lease-wraper.a-1.b-0 .lease-wraper-inner.a-1.b-0,
      .lease-wraper.a-1.b-1 .lease-wraper-inner.a-1.b-1,
      .lease-wraper.a-1.b-2 .lease-wraper-inner.a-1.b-2,
      .lease-wraper.a-1.b-3 .lease-wraper-inner.a-1.b-3,
      .lease-wraper.a-1.b-4 .lease-wraper-inner.a-1.b-4,
      .lease-wraper.a-2.b-0 .lease-wraper-inner.a-2.b-0,
      .lease-wraper.a-2.b-1 .lease-wraper-inner.a-2.b-1,
      .lease-wraper.a-2.b-2 .lease-wraper-inner.a-2.b-2,
      .lease-wraper.a-2.b-3 .lease-wraper-inner.a-2.b-3,
      .lease-wraper.a-2.b-4 .lease-wraper-inner.a-2.b-4,
      .lease-wraper.a-3.b-0 .lease-wraper-inner.a-3.b-0,
      .lease-wraper.a-3.b-1 .lease-wraper-inner.a-3.b-1,
      .lease-wraper.a-3.b-2 .lease-wraper-inner.a-3.b-2,
      .lease-wraper.a-3.b-3 .lease-wraper-inner.a-3.b-3,
      .lease-wraper.a-3.b-4 .lease-wraper-inner.a-3.b-4 {
        // display: block;
      }

      .copy-wrap {
        padding: 9rem 0 0;

        .copy-box {
          // line-height: 1.4;
          // text-align: center;
        }
      }

      .list-wrap {
        // @include flex_b;
        gap: 5rem 0;
        padding: 8rem 4rem 0;

        .list-area {
          // @include flex;
          // flex-direction: row-reverse;
          display: block;
          width: 100%;
          // background: #FFF;
          // border: 1px solid #EBEBEB;
          // border-radius: 1rem;
          // position: relative;
          // overflow: hidden;

          .img-box {
            width: 100%;
            height: 50rem;
            // position: relative;

            .img {
              // @include posi_cover;
              // background: #e0f0fe;

              img {
                // @include object_fit;
              }
            }

            .img.contain {
              img {
                // object-fit: contain;
              }
            }
          }

          .data-box {
            width: 100%;
            padding: 3rem 3rem 3rem;

            .labels {
              // @include flex;

              span {
                // @include flex_c_c;
                height: 2.4rem * 1.75;
                font-size: 2.4rem;
              }

              span:nth-of-type(1) {
                width: 6rem * 1.75;
                // background: $clr_blu;
                // color: #fff;
              }

              span:nth-of-type(2) {
                width: 8rem * 1.75;
                // background: #fff;
              }
            }

            .ttl {
              font-size: 2.8rem;
              margin: 2rem 0 0;
            }

            .data {
              margin: 2rem 0 0;
            }

            .data-txt {
              // @include flex;
              margin: 0.8rem 0 0;

              span {
                font-size: 2.6rem;
                // line-height: 1.4;
              }

              span:nth-of-type(1) {
                width: 18rem;
              }

              span:nth-of-type(2) {
                width: calc(100% - 18rem);
              }
            }

            .label {
              // display: inline-block;
              padding: 1rem 3rem 1.2rem;
              // background: $clr_grn;
              // border-radius: 100vh;
              font-size: 2.6rem;
              // color: #fff;
              margin: 3rem 0 0;
            }
          }

          .btn-box {
            position: static;
            padding: 0 3rem 3rem;

            .btn {}

            .btn.other {
              gap: 0 1.2rem;
              padding: 0 3rem;
            }
          }

          .cs-box {
            height: 50rem;

            .cs {
              // @include posi_c_l;
              // width: 100%;
              font-size: 2.6rem;
              // 
              // color: $clr_blu;
              // text-align: center;
            }
          }
        }
      }

      .none-wrap {
        padding: 8rem 4rem 0;

        .note-box {
          // text-align: center;
        }
      }

      .table-wrap {
        padding: 8rem 0 0;

        .btn-box {
          // @include flex_c_c;
          // width: 100%;
          height: 10rem * 1.25;
          // border-top: 1px solid #B2B2B2;
          // border-bottom: 1px solid #B2B2B2;
          // position: relative;

          span {}

          i {
            // @include transition;
            // @include posi_c_r;
            right: 4rem;
            width: 3rem;
            height: 3rem;
          }

          i::before,
          i::after {
            // @include transition;
            // @include posi_c_c;
            // content: "";
            // background: $clr_blu;
          }

          i::before {
            // width: 100%;
            // height: 2px;
          }

          i::after {
            // width: 2px;
            // height: 100%;
          }
        }

        .btn-box.act {
          i::after {
            // opacity: 0;
          }
        }

        .table-box {
          // @include transition;
          // overflow: hidden;
          overflow-x: scroll;

          table {
            width: 142rem;
            // border-right: 1px solid #d9d9d9;
          }

          tr {
            // @include flex_b;
            // border-bottom: 1px solid #d9d9d9;
          }

          th,
          td {
            // @include flex_c_c;
            padding: 2.2rem 0;
            // border-left: 1px solid #d9d9d9;
            // font-weight: 400;

            p {
              font-size: 2.6rem;
              // line-height: 1.6;

              span {
                // 
              }

              span.label {
                // display: inline-block;
                padding: 0 2rem;
                // border-radius: 100vh;
                // background: $clr_grn;
                // font-weight: 400;
                // color: #fff;
                margin: 0.8rem 0;
              }
            }

            a {
              // @include transition;
              // @include flex_s_c;
              gap: 0 2rem;
              margin: 0.8rem 0;

              span {
                padding: 0.8rem 0;
                font-size: 2.6rem;
                // line-height: 1;
                // color: $clr_blu;
                // border-bottom: solid 1px $clr_blu;
              }

              i {
                // @include flex_c_c;
                width: 1.6rem * 1.5;
              }
            }

            a:hover {
              // opacity: .7;
            }
          }

          th:nth-of-type(1),
          td:nth-of-type(1) {
            width: 46rem;
          }

          th:nth-of-type(2),
          td:nth-of-type(2) {
            width: 18rem;
          }

          th:nth-of-type(3),
          td:nth-of-type(3) {
            width: 22rem;
          }

          th:nth-of-type(4),
          td:nth-of-type(4) {
            width: calc(100% - 46rem - 18rem - 22rem);
          }

          th {
            // background: #f2f2f2;
            // 
          }

          td {}

          td:nth-of-type(1),
          td:nth-of-type(4) {
            // @include flex_s_c;
            // padding-left: 2.4rem;
          }

          td:nth-of-type(2),
          td:nth-of-type(3) {
            // text-align: center;
          }
        }

        .table-box.bt {
          // border-top: 1px solid #d9d9d9;
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageService.results-lease ___end
}