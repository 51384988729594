@use "../global" as *;

#pageService.business-lease {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-tab {
    padding: 8rem 0 8rem;

    .copy-wrap {
      .copy-box {
        text-align: center;
      }
    }

    .tab-wrap {
      margin: 8rem 0 0;
    }
  }

  // ______________________________________
  @include mq() {
    .sec-tab {
      padding: 9rem 0 9rem;

      .copy-wrap {
        .copy-box {
          // text-align: center;
        }
      }

      .tab-wrap {
        margin: 9rem 0 0;
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-lists {
    padding: 7rem 0 15rem;

    .copy-wrap {
      position: relative;

      .copy-box {
        text-align: center;
      }
    }

    .list-wrap {
      @include flex_b;
      gap: 5rem 0;
      margin: 7rem 0 0;
      position: relative;

      .list-area {
        width: 57.7rem;
        padding: 4.8rem;
        background: #fff;
        border-radius: 1rem;
        border: 1px solid #EBEBEB;

        .copy-box {
          text-align: center;

          .ttl {
            font-size: 2rem;
            line-height: 1.6;

            span {
              font-size: 2.8rem;
            }
          }

          .txt {
            letter-spacing: 0;
            line-height: 1.6;
            margin: 2rem 0 0;
          }
        }

        .slide-box {
          margin: 2rem 0 0;
        }

        .btn-box {
          margin: 4rem 0 0;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-lists {
      padding: 8rem 0 16rem;

      .copy-wrap {
        // position: relative;

        .copy-box {
          // text-align: center;
        }
      }

      .list-wrap {
        // @include flex_b;
        gap: 6rem 0;
        margin: 8rem 0 0;
        // position: relative;

        .list-area {
          width: 100%;
          padding: 5rem 4rem;
          // background: #fff;
          // border-radius: 1rem;
          // border: 1px solid #EBEBEB;

          .copy-box {
            // text-align: center;

            .ttl {
              font-size: 3.2rem;
              // line-height: 1.6;

              span {
                font-size: 4.0rem;
              }
            }

            .txt {
              // letter-spacing: 0;
              // line-height: 1.6;
              margin: 3rem 0 0;
            }
          }

          .slide-box {
            margin: 3rem 0 0;
          }

          .btn-box {
            margin: 5rem 0 0;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageService.business-lease ___end
}