@use "../global" as *;

#pageLand.top {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-copy {
    padding: 8rem 0 10rem;

    .copy-wrap {
      .copy-box {
        text-align: center;
      }

      .lead-box {
        text-align: center;
        margin: 12rem 0 0;

        .txt {
          margin: 2rem 0 0;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-copy {
      padding: 9rem 0 11rem;

      .copy-wrap {
        .copy-box {
          // text-align: center;
        }

        .lead-box {
          // text-align: center;
          margin: 13rem 0 0;

          .txt {
            margin: 3rem 0 0;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-rr {
    padding: 0 0 10rem;

    .ttl-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }
    }

    .list-wrap {
      @include flex_b;
      gap: 6rem 0;
      margin: 7rem 0 0;
      position: relative;

      .list-area {
        width: 57rem;
        border: 1px solid #EBEBEB;
        border-radius: 0 0 1rem 1rem;

        .img-box {}

        .copy-box {
          @include flex_c_c;
          height: 9rem;
          position: relative;

          span {
            font-size: 1.8rem;

          }

          .btn {
            @include posi_c_r;
            right: 2.4rem;
          }
        }
      }

      .list-area:hover {
        .copy-box {
          .btn {
            background: $clr_blu;
          }
        }
      }
    }

    .copy-wrap {
      position: relative;

      .copy-box {
        text-align: center;
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-rr {
      padding: 0 0 11rem;

      .ttl-wrap {
        // position: relative;

        .ttl-box {
          // text-align: center;
        }
      }

      .list-wrap {
        // @include flex_b;
        gap: 7rem 0;
        margin: 8rem 0 0;
        // position: relative;

        .list-area {
          width: 100%;
          // border: 1px solid #EBEBEB;
          // border-radius: 0 0 1rem 1rem;

          .img-box {}

          .copy-box {
            // @include flex_c_c;
            height: 9rem * 1.5;
            // position: relative;

            span {
              font-size: 2.8rem;
              // 
              line-height: 1.4;
            }

            .btn {
              // @include posi_c_r;
              // right: 2.4rem;
            }
          }
        }

        .list-area:hover {
          .copy-box {
            .btn {
              // background: $clr_blu;
            }
          }
        }
      }

      .copy-wrap {
        // position: relative;

        .copy-box {
          line-height: 1.6;
          // text-align: center;
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  #Inquiry {
    position: relative;
    top: -12rem;
  }

  .sec-inquiry {
    padding: 8rem 0;

    .copy-wrap {
      position: relative;

      .copy-area {
        .ttl-box {
          text-align: center;
        }
      }

      .ank-area {
        margin: 5rem 0 0;

        .ank-box {
          flex-direction: column;
          gap: 1rem 0;
          width: calc((100% - 4px) / 5);
          height: 7rem;
          position: relative;

          i {
            @include posi_c_r;
            right: 1rem;
          }

          sup {
            font-size: 1.2rem;
            color: #fff;
          }
        }

        .ank-box:nth-of-type(1) {
          border-radius: 1rem 0 0 1rem;
        }

        .ank-box:nth-of-type(5) {
          border-radius: 0 1rem 1rem 0;
        }
      }
    }

    .ank-wrap {
      position: relative;
      top: -2rem;
    }

    .list-wrap {
      padding: 10rem 0 0;
      position: relative;

      .copy-area {
        .ttl-box {
          @include flex;
          align-items: baseline;
          gap: 0 3rem;

          .h2 {
            padding: 0 0 1rem;
            border-bottom: 1px solid $clr_blu;
          }

          .txt {}
        }
      }

      .data-area {
        @include flex_b_s;
        margin: 3rem 0 0;

        .img-box {
          width: 48rem;
        }

        .data-box {
          width: calc(100% - 48rem - 5rem);

          .ca {
            @include flex_b_s;
            align-items: baseline;
            margin: 0 0 4rem;
          }

          .ca-ttl {}

          .ca-txt {
            width: 45rem;
          }

          .ca-tel {
            @include flex_s_s;
            gap: 0 2.4rem;
            padding: 0 0 1rem 1.6rem;
            border-bottom: 1px dashed #B2B2B2;
            margin: 0 0 2.4rem;
            pointer-events: none;

            i {
              width: 3.2rem;
            }

            span {}
          }

          .ca-btn {
            margin: 1.6rem 5rem 0 1.6rem;
          }

          .ca-add {
            line-height: 1.6;

            span {
              display: inline-block;

              line-height: 1;
              margin: 0 0 1rem;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    #Inquiry {
      // position: relative;
      // top: -12rem;
    }

    .sec-inquiry {
      padding: 9rem 0;

      .copy-wrap {
        // position: relative;

        .copy-area {
          .ttl-box {
            // text-align: center;
          }
        }

        .ank-area {
          margin: 6rem 0 0;

          .ank-box {
            // flex-direction: column;
            gap: 1.4rem 0;
            width: calc((100% - 1px) / 2);
            height: 11rem;
            // position: relative;

            i {
              // @include posi_c_r;
              right: 1rem;
            }

            sup {
              font-size: 2.2rem;
              // color: #fff;
            }
          }

          .ank-box:nth-of-type(1) {
            width: 100%;
            border-radius: 1rem 1rem 0 0;
          }

          .ank-box:nth-of-type(4) {
            border-radius: 0 0 0 1rem;
          }

          .ank-box:nth-of-type(5) {
            border-radius: 0 0 1rem 0;
          }
        }
      }

      .ank-wrap {
        // position: relative;
        // top: -2rem;
      }

      .list-wrap {
        padding: 11rem 0 0;
        // position: relative;

        .copy-area {
          .ttl-box {
            // @include flex;
            // align-items: baseline;
            // gap: 0 3rem;
            display: block;

            .h2 {
              display: inline-block;
              padding: 0 0 2rem;
              // border-bottom: 1px solid $clr_blu;
            }

            .txt {
              line-height: 1.4;
              margin: 2rem 0 0;
            }
          }
        }

        .data-area {
          // @include flex_b_s;
          display: block;
          margin: 4rem 0 0;

          .img-box {
            width: 100%;
          }

          .data-box {
            width: 100%;
            margin: 5rem 0 0;

            .ca {
              // @include flex_b_s;
              // align-items: baseline;
              display: block;
              margin: 0 0 5rem;
            }

            .ca-ttl {}

            .ca-txt {
              width: 65rem;
              margin: 3rem 0 0;
            }

            .ca-tel {
              // @include flex_s_s;
              gap: 0 2rem;
              padding: 0 0 2rem 2rem;
              // border-bottom: 1px dashed #B2B2B2;
              margin: 0 0 3.4rem;
              pointer-events: auto;

              i {
                width: 3.2rem * 1.5;
              }

              span {}
            }

            .ca-btn {
              margin: 2.6rem 2rem 0 2rem;
            }

            .ca-add {
              // line-height: 1.6;

              span {
                // display: inline-block;
                // 
                // line-height: 1;
                // margin: 0 0 1rem;
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageLand.top ___end
}