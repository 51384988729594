// $media_pc: 1440;
$media_pc: 1240;
$media_sp: 750;
$media_mp: 599;
$nav_sp: 767;

$breakpoint: (
  pc_max: 'screen and (max-width: #{$media_pc}px)',
  pc_min: 'screen and (min-width: #{$media_sp + 1}px)',
  sp: 'screen and (max-width: #{$media_sp}px)',
  nav: 'screen and (max-width: #{$nav_sp}px)',
  mp: 'screen and (max-width: #{$nav_sp}px)',
);

$clr_wht: #fff;
$clr_blk: #000;
// $clr_gry: #;
$clr_blu: #015ea5;
$clr_blu2: #123b76;
$clr_grn: #00716D;
// $clr_ylw: #;
$clr_red: #7c0d22;

$grd_blu: linear-gradient(180deg, rgba(#e4f0f7, 1) 0%, rgba(#fff, 1) 100%);
$grd_blu2: linear-gradient(88deg, #122776 0%, #015EA5 68.79%, #79AAF4 131.21%);