@use "../global" as *;

#pageService {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .srv-com-tab-wrap {
    @include flex;

    .tab-box {
      width: calc(100% / 3);
      height: 16rem;
      position: relative;
      overflow: hidden;

      .img {
        @include transition;
      }

      .cover {
        @include transition;
        @include posi_cover;
        background: rgba(#000, .5);
      }

      .h3 {
        @include posi_c_l;
        width: 100%;
        color: #fff;
        text-align: center;
      }
    }

    .tab-box.act,
    .tab-box:hover {
      .img {
        transform: scale(1.05);
      }

      .cover {
        opacity: 0;
      }
    }
  }

  // ______________________________________
  @include mq() {
    .srv-com-tab-wrap {
      // @include flex;
      padding: 0 4rem;

      .tab-box {
        width: calc(100% / 1);
        height: 22rem;
        // position: relative;
        // overflow: hidden;

        .img {
          // @include transition;
        }

        .cover {
          // @include transition;
          // @include posi_cover;
          // background: rgba(#000, .5);
        }

        .h3 {
          // @include posi_c_l;
          // width: 100%;
          // color: #fff;
          // text-align: center;
        }
      }

      .tab-box:hover {
        .img {
          transform: scale(1.0);
        }

        .cover {
          opacity: 1;
        }
      }

      .tab-box.act {
        .img {
          transform: scale(1.0);
        }

        .cover {
          opacity: 0;
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageService ___end
}