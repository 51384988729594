@use "../global" as *;

#pageRecruit.top {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-cntnt {
    padding: 8rem 0 12rem;

    .ttl-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }

      .copy-box {
        text-align: center;
        margin: 2rem 0 0;
      }
    }

    .table-wrap {
      padding: 4.5rem 4rem;
      background: #fff;
      margin: 8rem 0 0;
      position: relative;

      .table-box {
        table {
          width: 100%;
          border-top: 1px solid #d9d9d9;

          tbody {
            tr {
              @include flex_b;
              border-bottom: 1px solid #d9d9d9;
            }

            th,
            td {
              padding: 1.2rem 0;
              font-size: 1.4rem;
              line-height: 1.8;
              text-align: left;
            }

            th {
              width: 22rem;
            }

            td {
              width: calc(100% - 22rem);
              padding-left: 2rem;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-cntnt {
      padding: 9rem 0 13rem;

      .ttl-wrap {
        // position: relative;

        .ttl-box {
          // text-align: center;
        }

        .copy-box {
          // text-align: center;
          margin: 3rem 0 0;
        }
      }

      .table-wrap {
        padding: 0rem 0rem;
        // background: #fff;
        margin: 9rem 0 0;
        // position: relative;

        .table-box {
          table {
            // width: 100%;
            // border-top: 1px solid #d9d9d9;

            tbody {
              tr {
                // @include flex_b;
                // border-bottom: 1px solid #d9d9d9;
              }

              th,
              td {
                padding: 2.2rem 4rem;
                font-size: 2.6rem;
                // line-height: 1.8;
                // text-align: left;
              }

              th {
                width: 100%;
                background: #f2f2f2;
              }

              td {
                width: 100%;
                padding-left: 4rem;
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageRecruit.top ___end
}