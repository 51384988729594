@use "../global" as *;

#pageInquiry.top {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-links {
    padding: 12rem 0 12rem;

    .links-wrap {
      @include flex;

      .link-area {
        @include flex_b;
        flex-direction: column;
        width: calc(100% / 3);
        padding: 4rem;
        border: 1px solid #EBEBEB;
        border-radius: 1rem;

        .copy-box {
          .h3 {
            text-align: center;
          }

          .txt {
            margin: 2rem 0 2rem;
          }
        }

        .img-box {
          margin: 3rem 0 0;
        }

        .btn-box {
          margin: 4rem 0 0;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-links {
      padding: 13rem 0 13rem;

      .links-wrap {
        // @include flex;
        gap: 5rem 0;
        padding: 0 4rem;

        .link-area {
          // @include flex_b;
          // flex-direction: column;
          display: block;
          width: 100%;
          padding: 5rem 4rem;
          // border: 1px solid #EBEBEB;
          // border-radius: 1rem;

          .copy-box {
            .h3 {
              // text-align: center;
            }

            .txt {
              margin: 3rem 0 3rem;
            }
          }

          .img-box {
            margin: 4rem 0 0;
          }

          .btn-box {
            margin: 5rem 0 0;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageInquiry.top ___end
}