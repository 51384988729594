@use "../global" as *;

#pageSustainability.green-building {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-copy {
    padding: 8rem 0 10rem;

    .copy-wrap {
      .copy-box {}
    }
  }

  // ______________________________________
  @include mq() {
    .sec-copy {
      padding: 9rem 0 11rem;

      .copy-wrap {
        .copy-box {}
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-lists {
    padding: 8rem 0 0;

    .ttl-wrap {
      margin: 0 0 7rem;
      position: relative;

      .ttl-box {
        text-align: center;
      }
    }

    .list-wrap {
      margin: 0 0 16rem;
      position: relative;

      .ttl-box {
        text-align: center;
      }

      .btn-box {
        border-top: 1px solid #B2B2B2;
        border-bottom: 1px solid #B2B2B2;
        margin: 4rem 0 0;
      }

      .table-box {
        @include transition;
        overflow: hidden;

        table {
          width: 100%;
          border-right: 1px solid #d9d9d9;
        }

        tr {
          @include flex_b;
          border-bottom: 1px solid #d9d9d9;
        }

        th,
        td {
          @include flex_c_c;
          padding: 1.2rem 0;
          border-left: 1px solid #d9d9d9;
          font-weight: 400;

          p {
            font-size: 1.4rem;
            line-height: 1.6;
          }

          a {
            @include transition;
            @include flex_s_c;
            gap: 0 1rem;

            span {
              padding: 0.4rem 0;
              font-size: 1.4rem;
              line-height: 1;
              color: $clr_blu;
              border-bottom: solid 1px $clr_blu;
            }

            i {
              @include flex_c_c;
              width: 1.6rem;
            }
          }

          a:hover {
            opacity: .7;
          }
        }

        th:nth-of-type(1),
        td:nth-of-type(1) {
          width: 20rem;
        }

        th:nth-of-type(2),
        td:nth-of-type(2) {
          width: 15rem;
        }

        th:nth-of-type(3),
        td:nth-of-type(3) {
          width: calc(100% - 20rem - 15rem);
        }

        th {
          background: #F2F7FB;
        }

        td {}

        td:nth-of-type(3) {
          @include flex_s_c;
          padding-left: 2.4rem;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-lists {
      padding: 9rem 0 0;

      .ttl-wrap {
        margin: 0 0 8rem;
        // position: relative;

        .ttl-box {
          // text-align: center;

          .h2 {
            line-height: 1.4;
          }
        }
      }

      .list-wrap {
        margin: 0 0 17rem;
        // position: relative;

        .ttl-box {
          // text-align: center;
        }

        .btn-box {
          // border-top: 1px solid #B2B2B2;
          // border-bottom: 1px solid #B2B2B2;
          margin: 5rem 0 0;
        }

        .table-box {
          // @include transition;
          // overflow: hidden;

          table {
            // width: 100%;
            // border-right: 1px solid #d9d9d9;
          }

          tr {
            // @include flex_b;
            // border-bottom: 1px solid #d9d9d9;
          }

          th,
          td {
            // @include flex_c_c;
            padding: 2.2rem 0;
            // border-left: 1px solid #d9d9d9;
            // font-weight: 400;

            p {
              font-size: 2.6rem;
              // line-height: 1.6;
            }

            a {
              // @include transition;
              // @include flex_s_c;
              gap: 0 2rem;

              span {
                padding: 0.8rem 0;
                font-size: 2.6rem;
                // line-height: 1;
                // color: $clr_blu;
                // border-bottom: solid 1px $clr_blu;
              }

              i {
                // @include flex_c_c;
                width: 1.6rem * 1.5;
              }
            }

            a:hover {
              // opacity: .7;
            }
          }

          th:nth-of-type(1),
          td:nth-of-type(1) {
            width: 16rem;
          }

          th:nth-of-type(2),
          td:nth-of-type(2) {
            width: 10rem;
          }

          th:nth-of-type(3),
          td:nth-of-type(3) {
            width: calc(100% - 16rem - 10rem);
          }

          th {
            // background: #F2F7FB;
          }

          td {}

          td:nth-of-type(3) {
            // @include flex_s_c;
            padding-left: 1rem;
          }
        }

        .table-box.ba {
          td:nth-of-type(1) {
            justify-content: flex-start;
            padding-left: 0.6rem;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-goals {
    padding: 8rem 0;

    .goals-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }

      .copy-box {
        padding: 2rem 8rem;
        background: #fff;
        border: solid 0.8rem rgba($clr_blu, .05);
        margin: 2rem 0 0;

        .txt {
          @include flex_s_c;
          gap: 0 1.6rem;
          padding: 0 0 1rem 1.6rem;
          border-bottom: solid 1px $clr_blu;
          margin: 2rem 0;

          i {
            width: 1rem;
            height: 1rem;
            background: $clr_blu;
            border-radius: 50%;
          }

          span {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-goals {
      padding: 9rem 0;

      .goals-wrap {
        // position: relative;

        .ttl-box {
          // text-align: center;
        }

        .copy-box {
          padding: 2rem 3rem;
          // background: #fff;
          border: solid 1rem rgba($clr_blu, .05);
          margin: 3rem 0 0;

          .txt {
            // @include flex_s_c;
            gap: 0 2rem;
            padding: 0 0 2rem 0rem;
            // border-bottom: solid 1px $clr_blu;
            margin: 3rem 0;

            i {
              width: 2rem;
              height: 2rem;
              // background: $clr_blu;
              // border-radius: 50%;
            }

            span {
              width: calc(100% - 2rem - 2rem);
              font-size: 2.6rem;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-note {
    padding: 8rem 0 15rem;
  }

  // ______________________________________
  @include mq() {
    .sec-note {
      padding: 9rem 0 16rem;
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageSustainability.green-building ___end
}