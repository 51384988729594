@use "../global" as *;

#pageCompany.outline {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-cp {
    padding: 8rem 0 0;

    .ttl-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }
    }

    .cp-wrap {
      margin: 8rem 0 0;
      position: relative;

      .cp-area {
        padding: 4.5rem 4rem;
        background: #fff;
        position: relative;

        .table-box {
          >table {
            width: 100%;
            border-top: 1px solid #d9d9d9;

            tbody {
              tr {
                @include flex_b;
                border-bottom: 1px solid #d9d9d9;
              }

              th,
              td {
                padding: 1.2rem 0;
                font-size: 1.4rem;
                line-height: 1.8;
                text-align: left;
              }

              th {
                width: 22rem;
                font-weight: 500;
                letter-spacing: 0;
              }

              td {
                width: calc(100% - 22rem);
                padding-left: 2rem;
                letter-spacing: 0.1em;
              }

              td.indent {
                span {
                  display: block;
                }
              }

              td.clr-blu {
                color: $clr_blu;

                a {
                  @include transition;
                }

                a:hover {
                  opacity: .7;
                }
              }
            }
          }

          .other-1 {
            div {
              @include flex;
              padding: 1.2rem 0;
              border-bottom: 1px solid #d9d9d9;
            }

            div:first-of-type {
              padding-top: 0;
            }

            div:last-of-type {
              border-bottom: none;
            }

            span:nth-of-type(1) {
              width: 21rem;
            }

            span:nth-of-type(2) {
              width: calc(100% - 21rem);
            }
          }

          .other-2 {
            padding: 0 0 2rem;

            table {
              width: 100%;
              border-top: 1px solid #d9d9d9;
              border-right: 1px solid #d9d9d9;
            }

            tr {}

            th,
            td {
              font-weight: 400;
              border-left: 1px solid #d9d9d9;
              text-align: center;
            }

            th {
              width: 27rem;
              background: #f2f2f2;
            }

            th:first-of-type {
              width: calc(100% - 27rem - 27rem);
            }

            td {
              width: 27rem;
            }

            td:first-of-type {
              width: calc(100% - 27rem - 27rem);
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-cp {
      padding: 9rem 0 0;

      .ttl-wrap {
        // position: relative;

        .ttl-box {
          // text-align: center;
        }
      }

      .cp-wrap {
        margin: 9rem 0 0;
        // position: relative;

        .cp-area {
          padding: 0rem 0rem;
          // background: #fff;
          // position: relative;

          .table-box {
            >table {
              // width: 100%;
              // border-top: 1px solid #d9d9d9;

              tbody {
                tr {
                  // @include flex_b;
                  // border-bottom: 1px solid #d9d9d9;
                }

                th,
                td {
                  padding: 2.2rem 4rem;
                  font-size: 2.6rem;
                  // line-height: 1.8;
                  // text-align: left;
                }

                th {
                  width: 100%;
                  background: #f2f2f2;
                  // font-weight: 500;
                  // letter-spacing: 0;
                }

                td {
                  width: 100%;
                  padding-left: 4rem;
                  letter-spacing: 0;
                }

                td.indent {
                  span {
                    // display: block;
                    padding-left: 6.4rem;
                    text-indent: -6.4rem;
                  }
                }

                td.clr-blu {
                  // color: $clr_blu;

                  a {
                    // @include transition;
                  }

                  a:hover {
                    // opacity: .7;
                  }
                }
              }
            }

            .other-1 {
              div {
                // @include flex;
                padding: 2.2rem 0;
                // border-bottom: 1px solid #d9d9d9;
              }

              div:first-of-type {
                // padding-top: 0;
              }

              div:last-of-type {
                // border-bottom: none;
              }

              span:nth-of-type(1) {
                width: 100%;
              }

              span:nth-of-type(2) {
                width: 100%;
                padding-left: 7.2rem;
              }
            }

            .other-2 {
              padding: 0 0 3rem;

              table {
                // width: 100%;
                // border-top: 1px solid #d9d9d9;
                // border-right: 1px solid #d9d9d9;
              }

              tr {}

              th,
              td {
                @include flex_c_c;
                // font-weight: 400;
                letter-spacing: 0;
                padding: 2.2rem 0;
                // border-left: 1px solid #d9d9d9;
                // text-align: center;
              }

              th {
                width: 19rem;
                background: #f2f2f2;
              }

              th:first-of-type {
                width: calc(100% - 19rem - 19rem);
              }

              td {
                width: 19rem;
              }

              td:first-of-type {
                width: calc(100% - 19rem - 19rem);
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-hist {
    padding: 8rem 0 0;
    margin: 8rem 0 0;

    .ttl-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }
    }

    .hist-wrap {
      margin: 8rem 0 0;
      position: relative;

      .hist-area {
        position: relative;

        .copy-box {
          text-align: center;
        }

        .figure-box {
          margin: 8rem 0 0;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-hist {
      padding: 9rem 0 0;
      margin: 9rem 0 0;

      .ttl-wrap {
        .ttl-box {
          // text-align: center;
        }
      }

      .hist-wrap {
        margin: 9rem 0 0;
        // position: relative;

        .hist-area {
          // position: relative;

          .copy-box {
            // text-align: center;
          }

          .figure-box {
            margin: 9rem 0 0;
            position: relative;
            overflow-x: scroll;

            .img {
              width: 134rem;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-pal {
    padding: 8rem 0 0;
    margin: 12rem 0 5rem;

    .ttl-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }
    }

    .pal-wrap {
      margin: 8rem 0 0;
      position: relative;

      .pal-area {
        padding: 5.5rem 4rem;
        background: #fff;
        position: relative;

        .pal-box {
          margin: 0 0 6rem;

          .h3 {
            padding: 0 0 1.6rem;
            border-bottom: 1px solid #d9d9d9;
            position: relative;
          }

          .h3::before {
            @include posi_b_l;
            content: "";
            width: 15rem;
            height: 1px;
            background: $clr_blu;
          }

          .txt {
            padding: 1.2rem 0;
            border-bottom: 1px solid #d9d9d9;
          }

          .txt.table {
            @include flex;

            span:nth-of-type(odd) {
              width: 22rem;
              
              letter-spacing: 0;
            }

            span:nth-of-type(even) {
              @include flex;
              width: calc(100% - 22rem);
              padding-left: 2rem;

              span:nth-of-type(odd) {
                width: 13rem;
                font-weight: 400;
              }

              span:nth-of-type(even) {
                width: calc(100% - 13rem);
              }
            }
          }

          .txt.indent {
            span {
              display: block;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-pal {
      padding: 9rem 0 0;
      margin: 13rem 0 6rem;

      .ttl-wrap {
        // position: relative;

        .ttl-box {
          // text-align: center;
        }
      }

      .pal-wrap {
        margin: 9rem 0 0;
        // position: relative;

        .pal-area {
          // padding: 5.5rem 4rem;
          // background: #fff;
          // position: relative;

          .pal-box {
            margin: 0 0 7rem;

            .h3 {
              padding: 0 0 2.6rem;
              // border-bottom: 1px solid #d9d9d9;
              // position: relative;
            }

            .h3::before {
              // @include posi_b_l;
              // content: "";
              width: 15rem * 1.5;
              // height: 1px;
              // background: $clr_blu;
            }

            .txt {
              padding: 2.2rem 0;
              // border-bottom: 1px solid #d9d9d9;
            }

            .txt.table {
              // @include flex;

              span:nth-of-type(odd) {
                width: 20rem;
                // 
                // letter-spacing: 0;
              }

              span:nth-of-type(even) {
                @include flex;
                width: calc(100% - 20rem);
                padding-left: 0rem;

                span:nth-of-type(odd) {
                  width: 20rem;
                  // font-weight: 400;
                }

                span:nth-of-type(even) {
                  width: calc(100% - 20rem);
                }
              }
            }

            .txt.indent {
              span {
                // display: block;
                padding-left: 3.4rem;
                text-indent: -3.4rem;
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageCompany.outline ___end
}