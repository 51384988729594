@use "../global" as *;

// ______________________________________
// ______________________________________
// ______________________________________
.com-content {
  width: 124rem;
  padding: 0 2rem;
  margin: 0 auto;
}

// ______________________________________
@include mq() {
  .com-content {
    width: 75rem;
    padding: 0 4rem;
    // margin: 0 auto;
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-note {
  font-size: 1.1rem;
  line-height: 1.4;
  letter-spacing: 0;
}

// ______________________________________
@include mq() {
  .com-note {
    font-size: 2.2rem;
    // line-height: 1.4;
    // letter-spacing: 0;
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-wm-vr {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

// ______________________________________
@include mq() {
  .com-wm-vr {
    // -ms-writing-mode: tb-rl;
    // writing-mode: vertical-rl;
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-cap {
  font-size: 1rem;
  letter-spacing: 0;
  color: #fff;
  text-align: right;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .5), -1px 1px 3px rgba(0, 0, 0, .5), 1px -1px 3px rgba(0, 0, 0, .5), -1px -1px 3px rgba(0, 0, 0, .5);
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.com-cap.left {
  text-align: left;
  right: auto;
  left: 0.5rem;
}

.com-cap.clr-w {
  color: #fff;
  text-shadow: none;
}

.com-cap.clr-b {
  color: #333333;
  text-shadow: none;
}

.com-cap-2 {
  @include posi_b_r;
  padding: 0.6rem 0.8rem;
  background: rgba(#000, 0.5);
  font-size: 1.1rem;

  color: #fff;
}

// ______________________________________
@include mq() {
  .com-cap {
    font-size: 2rem;
    // letter-spacing: 0;
    // color: #fff;
    // text-shadow: 1px 1px 3px rgba(0, 0, 0, .5), -1px 1px 3px rgba(0, 0, 0, .5), 1px -1px 3px rgba(0, 0, 0, .5), -1px -1px 3px rgba(0, 0, 0, .5);
    // position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  .com-cap.left {
    // right: auto;
    left: 1rem;
  }

  .com-cap.clr-w {
    // color: #fff;
    // text-shadow: none;
  }

  .com-cap.clr-b {
    // color: #333333;
    // text-shadow: none;
  }

  .com-cap-2 {
    // @include posi_b_r;
    // padding: 0.6rem 0.8rem;
    // background: rgba(#000, 0.5);
    font-size: 2.0rem;
    // 
    // color: #fff;
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-sup {
  font-size: 1rem;
  vertical-align: top;
  position: relative;
  top: -0.1em;
}

// ______________________________________
@include mq() {}

// ______________________________________
// ______________________________________
// ______________________________________
.com-loadwrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: #fff;
  opacity: 1;
}

.com-loadwrap.no-act {
  animation-name: loadAnime;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;

  @keyframes loadAnime {
    0% {
      z-index: 99999;
      opacity: 1;
    }

    90% {
      z-index: 99999;
    }

    100% {
      z-index: -1;
      opacity: 0;
    }
  }
}

// ______________________________________
@include mq() {}

// ______________________________________
// ______________________________________
// ______________________________________
.com-o-fit {
  @include posi_t_l;
  width: 100%;
  height: 100%;

  img {
    @include object_fit;
  }
}

.com-o-fit.top {
  img {
    @include object_fit(center, top);
  }
}

.com-o-fit.right {
  img {
    @include object_fit(right, center);
  }
}

.com-o-fit.bottom {
  img {
    @include object_fit(center, bottom);
  }
}

.com-o-fit.left {
  img {
    @include object_fit(left, center);
  }
}

// ______________________________________
@include mq() {}

// ______________________________________
// ______________________________________
// ______________________________________
.com-modal {
  .modal {}

  .modal__overlay {
    @include flex_c_c;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
  }

  .modal__container {
    max-width: 50rem;
    max-height: 100vh;
    padding: 3rem;
    background: #fff;
    overflow-y: auto;
  }

  .modal__header {
    @include flex_e;
  }

  .modal__title {
    font-size: 2rem;
  }

  .modal__close::before {
    content: "\2715";
  }

  .modal__content {}

  /* Animation */
  @keyframes mmfadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes mmfadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes mmslideIn {
    from {
      transform: translateY(10%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes mmslideOut {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-10%);
    }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  }

  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  }

  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  }

  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  }

  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }
}

// ______________________________________
@include mq() {}

// ______________________________________
// ______________________________________
// ______________________________________
.com-swipe-icon-box {
  display: none;
}

// ______________________________________
@include mq() {
  .com-swipe-icon-box {
    @include posi_c_c;
    display: block;
    width: 40rem;
    padding: 6rem 0;
    background: rgba(#000, .7);
    border-radius: 1rem;
    z-index: 5;

    .com-swipe-icon {
      text-align: center;

      span {
        font-size: 8rem;
        color: #fff;
      }
    }

    .com-swipe-icon {
      span {
        transform: translateX(5rem);
        animation-name: comSwipeIconAnime;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-fill-mode: none;
        animation-play-state: running;

        @keyframes comSwipeIconAnime {
          0% {
            transform: translateX(5rem);
          }

          100% {
            transform: translateX(-5rem);
          }
        }
      }
    }

    .com-swipe-icon-txt {
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.4;
      color: #fff;
      text-align: center;
      margin: 3rem 0 0;
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.js-com-fade {
  @include transition;
  transition-duration: 2000ms;
  opacity: 0;
  transform: translateY(2rem);
}

.js-com-fade.act {
  opacity: 1;
  transform: translateY(0rem);
}

.js-com-blur {
  @include transition;
  transition-duration: 2000ms;
  opacity: 0;
  filter: blur(10px);
}

.js-com-blur.act {
  opacity: 1;
  filter: blur(0px);
}

.js-com-img-fade {
  @include transition;
  transition-duration: 2000ms;
  opacity: 0;
  transform: scale(1.05);
  filter: blur(10px);
}

.js-com-img-fade.act {
  opacity: 1;
  transform: scale(1.0);
  filter: blur(0px);
}

.js-parallax-box {
  overflow: hidden;

  .js-parallax-inner {
    height: calc(100% + 100px);
    will-change: transform;
  }
}

// ______________________________________
@include mq() {
  .js-com-fade {
    // @include transition;
    // transition-duration: 2000ms;
    // opacity: 0;
    // transform: translateY(2rem);
  }

  .js-com-fade.act {
    // opacity: 1;
    // transform: translateY(0rem);
  }

  .js-com-blur {
    // @include transition;
    // transition-duration: 2000ms;
    // opacity: 0;
    // transform: scale(1.1);
    // filter: blur(10px);
  }

  .js-com-blur.act {
    // opacity: 1;
    // transform: scale(1.0);
    // filter: blur(0px);
  }

  .js-com-img-fade {
    // @include transition;
    // transition-duration: 2000ms;
    // opacity: 0;
    // transform: scale(1.1);
    // filter: blur(10px);
  }

  .js-com-img-fade.act {
    // opacity: 1;
    // transform: scale(1.0);
    // filter: blur(0px);
  }

  .js-parallax-box {
    // overflow: hidden;

    .js-parallax-inner {
      height: calc(100% + 50px);
      // will-change: transform;
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-sec-main {
  background: $clr_blu;

  .main-wrap {
    height: 29rem;
    position: relative;

    .img-box {
      @include posi_cover;

      .img {}

      .cover {
        @include posi_cover;
        mix-blend-mode: multiply;
      }

      .cover.clr-42210b {
        background: rgba(#42210b, .25);
      }

      .cover.clr-123b76 {
        background: rgba(#123b76, .25);
      }

      .cover.clr-42210b {
        background: rgba(#42210b, .25);
      }

      .cover.clr-015ea5 {
        background: rgba(#015ea5, .25);
      }

      .cover.clr-000 {
        background: rgba(#000, .25);
      }
    }

    .cover-box {
      @include posi_t_l;
      width: 100%;
      height: 36rem;
    }

    .ttl-box {
      @include posi_c_l;
      width: 100%;
      color: #fff;
      text-align: center;
    }
  }
}

// ______________________________________
@include mq() {
  .com-sec-main {
    // background: $clr_blu;

    .main-wrap {
      // height: 29rem;
      // position: relative;

      .img-box {
        // @include posi_cover;

        .img {}

        .cover {
          // @include posi_cover;
          // mix-blend-mode: multiply;
        }

        .cover.clr-42210b {
          // background: rgba(#42210b, .25);
        }

        .cover.clr-123b76 {
          // background: rgba(#123b76, .25);
        }
      }

      .cover-box {
        // @include posi_t_l;
        // width: 100%;
        // height: 36rem;
      }

      .ttl-box {
        // @include posi_c_l;
        // width: 100%;
        // color: #fff;
        // text-align: center;
      }
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-btn {
  @include transition;
  @include flex_c_c;
  width: 10rem;
  height: 5.6rem;
  background: #afafaf;
  border-radius: 100vh;

  span {
    @include transition;
    font-size: 1.6rem;
    font-weight: 900;
    color: #fff;
  }
}

.com-btn.sz-s {
  width: 8rem;
  height: 4.5rem;

  span {
    padding: 0 0 0.2rem;
  }
}

.com-btn.clr-wht {
  background: rgba(#fff, .2);
}

.com-btn-2 {
  @include transition;
  @include flex_c_c;
  width: 20rem;
  height: 4.5rem;
  background: #fff;
  border-radius: 100vh;
  position: relative;

  span {
    @include transition;
    padding: 0 0 0.4rem;
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 0.1em;
  }

  i {
    @include transition;
    @include posi_t_r;
    top: 1.5rem;
    right: 2.4rem;
    font-size: 1.6rem;
    font-weight: 900;
    color: $clr_blu;
  }
}

.com-btn-3 {
  @include transition;
  @include flex_c_c;
  height: 7rem;
  background: #fff;
  border: 1px solid $clr_blu;
  border-radius: 100vh;
  position: relative;

  span {
    @include transition;
    font-size: 2rem;

    letter-spacing: 0.1em;
  }

  i {
    @include transition;
    @include posi_c_r;
    right: 3.5rem;
    font-size: 1.6rem;
    font-weight: 900;
    color: $clr_blu;
  }
}

.com-btn-3.sz-s {
  height: 6rem;

  span {
    letter-spacing: 0em;
  }
}

.com-btn-3:hover {
  background: $clr_blu;

  span {
    color: #fff;
  }

  i {
    color: #fff;
  }
}

.com-btn-4 {
  @include transition;
  @include flex_c_c;
  display: inline-flex;
  gap: 0 1.6rem;
  height: 4rem;
  padding: 0 2.8rem;
  background: rgba($clr_blu, .05);
  border-radius: 100vh;

  span {
    @include transition;
    font-size: 1.4rem;

  }

  i {
    @include flex_c_c;
    width: 1.4rem;

    path {
      @include transition;
    }
  }
}

.com-btn-4.pdf {
  i {
    width: 2rem;

    path {}
  }
}

.com-btn-4:hover {
  background: rgba($clr_blu, 1);

  span {
    color: #fff;
  }

  i {
    path {
      stroke: #fff;
    }
  }
}

.com-btn-4.pdf:hover {
  i {
    path {
      stroke: initial;
      fill: #fff;
    }
  }
}

// ______________________________________
@include mq() {
  .com-btn {
    // @include transition;
    // @include flex_c_c;
    width: 10rem * 1.25;
    height: 5.6rem * 1.25;
    // background: #afafaf;
    // border-radius: 100vh;

    span {
      // @include transition;
      font-size: 2.6rem;
      // font-weight: 900;
      // color: #fff;
    }
  }

  .com-btn.sz-s {
    width: 8rem * 1.25;
    height: 4.5rem * 1.25;

    span {
      // padding: 0 0 0.2rem;
    }
  }

  .com-btn.clr-wht {
    // background: rgba(#fff, .2);
  }

  .com-btn-2 {
    // @include transition;
    // @include flex_c_c;
    width: 20rem * 1.25;
    height: 4.5rem * 1.25;
    // background: #fff;
    // border-radius: 100vh;
    // position: relative;

    span {
      // @include transition;
      padding: 0 2rem 0.8rem 0;
      font-size: 3rem;
      // font-weight: 900;
      letter-spacing: 0em;
    }

    i {
      // @include transition;
      // @include posi_t_r;
      top: 1.6rem;
      right: 3rem;
      font-size: 2.6rem;
      // font-weight: 900;
      // color: $clr_blu;
    }
  }

  .com-btn-3 {
    // @include transition;
    // @include flex_c_c;
    height: 7rem * 1.25;
    // background: #fff;
    // border: 1px solid $clr_blu;
    // border-radius: 100vh;
    // position: relative;

    span {
      // @include transition;
      font-size: 3rem;
      // 
      // letter-spacing: 0.1em;
    }

    i {
      // @include transition;
      // @include posi_c_r;
      right: 4rem;
      font-size: 2.6rem;
      // font-weight: 900;
      // color: $clr_blu;
    }
  }

  .com-btn-3.sz-s {
    height: 6rem * 1.25;

    span {
      // letter-spacing: 0em;
    }
  }

  .com-btn-3:hover {
    background: #fff;

    span {
      color: #000;
    }

    i {
      color: $clr_blu;
    }
  }

  .com-btn-4 {
    // @include transition;
    // @include flex_c_c;
    // display: inline-flex;
    gap: 0 2.4rem;
    height: 4rem * 1.5;
    padding: 0 4rem;
    // background: rgba($clr_blu, .05);
    // border-radius: 100vh;

    span {
      // @include transition;
      font-size: 2.6rem;
      // 
    }

    i {
      // @include flex_c_c;
      width: 1.6rem * 1.5;

      path {
        // @include transition;
      }
    }
  }

  .com-btn-4.pdf {
    i {
      width: 2rem * 1.5;

      path {}
    }
  }

  .com-btn-4:hover {
    // background: rgba($clr_blu, 1);

    span {
      // color: #fff;
    }

    i {
      path {
        // stroke: #fff;
      }
    }
  }

  .com-btn-4.pdf:hover {
    i {
      path {
        // stroke: initial;
        // fill: #fff;
      }
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-link {
  @include transition;
  @include flex_s_c;
  display: inline-flex;
  gap: 0 1rem;

  span {
    padding: 0 0 0.4rem;
    font-size: 1.4rem;
    color: $clr_blu;
    border-bottom: solid 1px $clr_blu;
  }

  i {
    @include flex_c_c;
    width: 1.6rem;
  }
}

.com-link:hover {
  opacity: .7;
}

// ______________________________________
@include mq() {
  .com-link {
    // @include transition;
    // @include flex_s_c;
    // display: inline-flex;
    gap: 0 2rem;

    span {
      padding: 0 0 0.8rem;
      font-size: 2.6rem;
      // color: $clr_blu;
      // border-bottom: solid 1px $clr_blu;
    }

    i {
      // @include flex_c_c;
      width: 1.6rem * 1.5;
    }
  }

  .com-link:hover {
    // opacity: .7;
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-h1 {
  font-size: 4.0rem;
  letter-spacing: 0.05em;
}

.com-h1.clr-blu {
  color: $clr_blu;
}

.com-h2 {
  font-size: 2.8rem;
  letter-spacing: 0.05em;
}

.com-h2-sub {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  color: $clr_blu;
  margin: 1rem 0 0;
}

.com-h3 {
  font-size: 2.0rem;
  letter-spacing: 0.05em;
}

.com-h3.clr-blu {
  color: $clr_blu;
}

.com-txt {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  line-height: 1.8;
}

// ______________________________________
@include mq() {
  .com-h1 {
    font-size: 5.2rem;
    // letter-spacing: 0.05em;
  }

  .com-h1.clr-blu {
    // color: $clr_blu;
  }

  .com-h2 {
    font-size: 4.0rem;
    // letter-spacing: 0.05em;
  }

  .com-h2-sub {
    font-size: 2.6rem;
    // letter-spacing: 0.05em;
    // color: $clr_blu;
    margin: 2rem 0 0;
  }

  .com-h3 {
    font-size: 3.2rem;
    // letter-spacing: 0.05em;
  }

  .com-h3.clr-blu {
    // color: $clr_blu;
  }

  .com-txt {
    font-size: 2.6rem;
    // letter-spacing: 0.05em;
    // line-height: 1.8;
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-switch-cover {
  @include transition;
  @include posi_t_l;
  width: 100%;
  max-height: 80rem;
  height: 100%;
  background: $grd_blu;
}

.com-switch-cover.none {
  opacity: 0;
}

// ______________________________________
@include mq() {
  .com-switch-cover {
    // @include posi_t_l;
    // width: 100%;
    // max-height: 80rem;
    // height: 100%;
    // background: $grd_blu;
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-slide-box {
  position: relative;

  .swiper {}

  .swiper-wrapper {}

  .swiper-slide {
    .img {
      height: 30rem;
      background: #e0f0fe;

      img {
        @include object_fit;
      }
    }

    .img.contain {
      img {
        object-fit: contain;
      }
    }

    .copy {
      margin: 1rem 0 0;

      p {
        line-height: 1.4;
      }
    }
  }

  .swiper-btn-prev,
  .swiper-btn-next {
    width: 4rem;
    position: absolute;
    top: calc(15rem - 2rem);
    z-index: 2;
    cursor: pointer;
  }

  .swiper-btn-prev {
    left: -2rem;
  }

  .swiper-btn-next {
    right: -2rem;
  }

  .swiper-pagination {
    margin: 1rem 0 0;
    position: static;
  }

  .swiper-pagination-bullet {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 0.8rem !important;
  }
}

// ______________________________________
@include mq() {
  .com-slide-box {
    // position: relative;

    .swiper {}

    .swiper-wrapper {}

    .swiper-slide {
      .img {
        height: 36rem;
        // background: #e0f0fe;

        img {
          // @include object_fit;
        }
      }

      .img.contain {
        img {
          // object-fit: contain;
        }
      }

      .copy {
        margin: 2rem 0 0;

        p {
          // line-height: 1.4;
        }
      }
    }

    .swiper-btn-prev,
    .swiper-btn-next {
      width: 5rem;
      // position: absolute;
      top: calc(18rem - 2.5rem);
      // z-index: 2;
      // cursor: pointer;
    }

    .swiper-btn-prev {
      left: -3rem;
    }

    .swiper-btn-next {
      right: -3rem;
    }

    .swiper-pagination {
      margin: 2rem 0 0;
      // position: static;
    }

    .swiper-pagination-bullet {
      width: 1.6rem;
      height: 1.6rem;
      margin: 0 1rem !important;
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-copy-box {
  width: 91rem;
  padding: 2rem 5rem;
  background: #fff;
  border: solid 0.8rem rgba($clr_blu, .05);
  text-align: center;
  margin: 0 auto;

  .copy {
    font-size: 1.6rem;
    line-height: 2.8;

    span {
      display: block;
      border-bottom: solid 1px rgba($clr_blu, .05);
    }

    span:last-of-type {
      border: none;
    }
  }
}

.com-copy-box-2 {
  width: 91rem;
  padding: 0 5.8rem;
  text-align: center;
  margin: 0 auto;

  .copy {
    font-size: 1.6rem;
    line-height: 2.8;

    span {
      display: block;
      border-bottom: solid 1px rgba($clr_blu, .05);
    }

    span:last-of-type {
      border: none;
    }
  }
}

.com-copy-box-2.border {
  padding: 2rem 5rem;
  border: solid 0.8rem rgba($clr_blu, .05);
}

// ______________________________________
@include mq() {
  .com-copy-box {
    width: 100%;
    padding: 2rem 3rem;
    // background: #fff;
    border: solid 1rem rgba($clr_blu, .05);
    // text-align: center;
    // margin: 0 auto;

    .copy {
      font-size: 2.6rem;
      line-height: 1.8;

      span {
        display: block;
        padding: 2rem 0;
        // border-bottom: solid 1px rgba($clr_blu, .05);
      }

      span:last-of-type {
        // border: none;
      }
    }
  }

  .com-copy-box-2 {
    width: 100%;
    padding: 0 0rem;
    text-align: left;
    // margin: 0 auto;

    .copy {
      font-size: 2.6rem;
      // line-height: 2.8;

      span {
        display: inline;
        border: none;
        text-decoration: underline 1px rgba($clr_blu, .05);
        text-underline-offset: 2.6rem;
      }

      span:last-of-type {
        // border: none;
      }
    }
  }

  .com-copy-box-2.border {
    padding: 2rem 3rem;
    border: solid 1rem rgba($clr_blu, .05);
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-tab-area-1 {
  @include flex_b;

  .tab-box {
    @include flex_c_c;
    gap: 0 1.6rem;
    width: 27rem;
    height: 5.5rem;
    border-bottom: 1px solid $clr_blu;

    span {
      @include transition;
      font-size: 1.4rem;

    }

    i {
      @include flex_c_c;
      width: 0.6rem;
    }
  }

  .tab-box.act,
  .tab-box:hover {
    span {
      color: $clr_blu;
    }
  }
}

.com-tab-area-2 {
  @include flex_c;

  .tab-box {
    @include transition;
    @include flex_c_c;
    height: 6rem;
    background: #fff;
    border: 1px solid $clr_blu;
    position: relative;
    overflow: hidden;

    div {
      @include transition;
      @include posi_cover;
      background: $grd_blu2;
      opacity: 0;
    }

    span {
      @include transition;
      font-size: 1.4rem;

      position: relative;
    }
  }

  .tab-box.act,
  .tab-box:hover {
    div {
      opacity: 1;
    }

    span {
      color: #fff;
    }
  }

  .tab-box.dis {
    background: #999999;
    pointer-events: none;

    span {
      color: #fff;
    }
  }
}

// ______________________________________
@include mq() {
  .com-tab-area-2 {
    // @include flex_c;

    .tab-box {
      // @include transition;
      // @include flex_c_c;
      height: 9rem;
      // background: #fff;
      // border: 1px solid $clr_blu;
      // position: relative;
      // overflow: hidden;

      div {
        // @include transition;
        // @include posi_cover;
        // background: $grd_blu2;
        // opacity: 0;
      }

      span {
        // @include transition;
        font-size: 2.6rem;
        // 
        // position: relative;
      }
    }

    .tab-box:hover {
      div {
        opacity: 0;
      }

      span {
        color: #000;
      }
    }

    .tab-box.act {
      div {
        // opacity: 1;
      }

      span {
        // color: #fff;
      }
    }

    .tab-box.dis {
      // background: #999999;
      // pointer-events: none;

      span {
        // color: #fff;
      }
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-ank-area {
  @include flex;
  gap: 1px 1px;

  .ank-box {
    @include transition;
    @include flex_c_c;
    gap: 0 1rem;
    height: 6rem;
    background: $grd_blu2;

    i {
      @include flex_c_c;
      width: 0.6rem;
    }

    span {
      font-size: 1.4rem;

      color: #fff;
    }
  }

  .ank-box:hover {
    opacity: .7;
  }
}

// ______________________________________
@include mq() {
  .com-ank-area {
    // @include flex;
    // gap: 1px 1px;

    .ank-box {
      // @include transition;
      // @include flex_c_c;
      gap: 0 2rem;
      height: 9rem;
      // background: $grd_blu2;

      i {
        // @include flex_c_c;
        width: 0.6rem * 1.5;
      }

      span {
        font-size: 2.6rem;
        // 
        // color: #fff;
      }
    }

    .ank-box:hover {
      opacity: 1;
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
.com-acc-btn-box {
  @include flex_c_c;
  width: 100%;
  height: 10rem;
  background: #F2F7FB;
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  position: relative;

  span {}

  i {
    @include transition;
    @include posi_c_r;
    right: 8rem;
    width: 2rem;
    height: 2rem;
  }

  i::before,
  i::after {
    @include transition;
    @include posi_c_c;
    content: "";
    background: $clr_blu;
  }

  i::before {
    width: 100%;
    height: 2px;
  }

  i::after {
    width: 2px;
    height: 100%;
  }
}

.com-acc-btn-box.act {
  i::after {
    opacity: 0;
  }
}

// ______________________________________
@include mq() {
  .com-acc-btn-box {
    // @include flex_c_c;
    // width: 100%;
    height: 10rem * 1.25;
    // background: #F2F7FB;
    // border-top: 1px solid #EBEBEB;
    // border-bottom: 1px solid #EBEBEB;
    // position: relative;

    span {}

    i {
      // @include transition;
      // @include posi_c_r;
      right: 4rem;
      width: 3rem;
      height: 3rem;
    }

    i::before,
    i::after {
      // @include transition;
      // @include posi_c_c;
      // content: "";
      // background: $clr_blu;
    }

    i::before {
      // width: 100%;
      // height: 2px;
    }

    i::after {
      // width: 2px;
      // height: 100%;
    }
  }

  .com-acc-btn-box.act {
    i::after {
      // opacity: 0;
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@include mq() {}

// ______________________________________
// ______________________________________
// ______________________________________

// ______________________________________
@include mq() {}