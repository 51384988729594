@use "../global" as *;

#pageService.results-investment {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-tab {
    padding: 8rem 0 8rem;

    .tab-wrap {}
  }

  // ______________________________________
  @include mq() {
    .sec-tab {
      padding: 9rem 0 9rem;

      .tab-wrap {}
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-investment {
    padding: 8rem 0 15rem;

    .tab-wrap {
      position: relative;

      .tab-area-2 {
        .tab-box {
          width: 27rem;
        }

        .tab-box:nth-of-type(1) {
          border-radius: 1rem 0 0 1rem;
        }

        .tab-box:nth-of-type(2) {
          border-radius: 0 1rem 1rem 0;
        }
      }
    }

    .investment-wraper {
      position: relative;
    }

    .investment-wraper-inner {
      display: none;
    }

    .investment-wraper-inner.act {
      display: block;
    }

    .list-wrap {
      @include flex_b;
      gap: 4rem 0;
      padding: 7rem 0 0;

      .list-area {
        @include flex;
        flex-direction: row-reverse;
        width: 58rem;
        background: #FFF;
        border: 1px solid #EBEBEB;
        border-radius: 1rem;
        position: relative;
        overflow: hidden;

        .img-box {
          width: 50%;
          position: relative;

          .img {
            @include posi_cover;
            background: #e0f0fe;

            img {
              @include object_fit;
            }
          }

          .img.contain {
            img {
              object-fit: contain;
            }
          }
        }

        .data-box {
          width: 50%;
          padding: 2rem 1rem 7rem;

          .labels {
            @include flex;

            span {
              @include flex_c_c;
              height: 2.4rem;
              font-size: 1.4rem;
            }

            span:nth-of-type(1) {
              width: 6rem;
              background: $clr_blu;
              color: #fff;
            }

            span:nth-of-type(2) {
              width: 8rem;
              background: #fff;
            }
          }

          .ttl {
            font-size: 1.8rem;
            margin: 1.2rem 0 0;
          }

          .data {
            margin: 1.2rem 0 0;
          }

          .data-txt {
            @include flex;
            margin: 0.4rem 0 0;

            span {
              font-size: 1.4rem;
              line-height: 1.4;
            }

            span:nth-of-type(1) {
              width: 10rem;
            }

            span:nth-of-type(2) {
              width: calc(100% - 10rem);
            }
          }

          .label {
            display: inline-block;
            padding: 0.6rem 2rem 0.8rem;
            background: $clr_grn;
            border-radius: 100vh;
            font-size: 1.4rem;
            color: #fff;
            margin: 2rem 0 0;
          }
        }

        .btn-box {
          @include posi_b_l;
          padding: 0 1rem 2rem;

          .btn {}
        }

        .cs-box {
          .cs {
            @include posi_c_l;
            width: 100%;
            font-size: 1.4rem;
            color: $clr_blu;
            text-align: center;
          }
        }
      }
    }

    .table-wrap {
      padding: 7rem 0 0;

      .table-box {
        table {
          width: 100%;
          border-top: 1px solid #d9d9d9;
          border-right: 1px solid #d9d9d9;
        }

        tr {
          @include flex_b;
          border-bottom: 1px solid #d9d9d9;
        }

        th,
        td {
          border-left: 1px solid #d9d9d9;

          p {
            padding: 1.2rem 0;
            border-bottom: 1px solid #d9d9d9;
            font-size: 1.4rem;

            letter-spacing: 0.1em;
            line-height: 1.8;
            text-align: center;
          }

          p:last-of-type {
            border-bottom: none;
          }
        }

        th:nth-of-type(1),
        td:nth-of-type(1) {
          @include flex_c_c;
          width: calc(100% - 39rem - 33rem - 15rem - 15rem);
        }

        th:nth-of-type(2),
        td:nth-of-type(2) {
          width: 39rem;
        }

        th:nth-of-type(3),
        td:nth-of-type(3) {
          width: 33rem;
        }

        th:nth-of-type(4),
        td:nth-of-type(4) {
          width: 15rem;
        }

        th:nth-of-type(5),
        td:nth-of-type(5) {
          width: 15rem;
        }

        td:nth-of-type(4),
        td:nth-of-type(5) {
          p {
            font-weight: 400;
          }
        }

        th {
          background: #f2f2f2;
        }

        td {
          background: #fff;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-investment {
      padding: 9rem 0 16rem;

      .tab-wrap {
        padding: 0 4rem;
        // position: relative;

        .tab-area-2 {
          .tab-box {
            width: 50%;
          }

          .tab-box:nth-of-type(1) {
            // border-radius: 1rem 0 0 1rem;
          }

          .tab-box:nth-of-type(2) {
            // border-radius: 0 1rem 1rem 0;
          }
        }
      }

      .investment-wraper {
        // position: relative;
      }

      .investment-wraper-inner {
        // display: none;
      }

      .investment-wraper-inner.act {
        // display: block;
      }

      .list-wrap {
        // @include flex_b;
        gap: 5rem 0;
        padding: 8rem 4rem 0;

        .list-area {
          // @include flex;
          // flex-direction: row-reverse;
          display: block;
          width: 100%;
          // background: #FFF;
          // border: 1px solid #EBEBEB;
          // border-radius: 1rem;
          // position: relative;
          // overflow: hidden;

          .img-box {
            width: 100%;
            height: 50rem;
            // position: relative;

            .img {
              // @include posi_cover;
              // background: #e0f0fe;

              img {
                // @include object_fit;
              }
            }

            .img.contain {
              img {
                // object-fit: contain;
              }
            }
          }

          .data-box {
            width: 100%;
            padding: 3rem 3rem 3rem;

            .labels {
              // @include flex;

              span {
                // @include flex_c_c;
                height: 2.4rem * 1.75;
                font-size: 2.4rem;
              }

              span:nth-of-type(1) {
                width: 6rem * 1.75;
                // background: $clr_blu;
                // color: #fff;
              }

              span:nth-of-type(2) {
                width: 8rem * 1.75;
                // background: #fff;
              }
            }

            .ttl {
              font-size: 2.8rem;
              margin: 2rem 0 0;
            }

            .data {
              margin: 2rem 0 0;
            }

            .data-txt {
              // @include flex;
              margin: 0.8rem 0 0;

              span {
                font-size: 2.6rem;
                // line-height: 1.4;
              }

              span:nth-of-type(1) {
                width: 18rem;
              }

              span:nth-of-type(2) {
                width: calc(100% - 18rem);
              }
            }

            .label {
              // display: inline-block;
              padding: 1rem 3rem 1.2rem;
              // background: $clr_grn;
              // border-radius: 100vh;
              font-size: 2.6rem;
              // color: #fff;
              margin: 3rem 0 0;
            }
          }

          .btn-box {
            position: static;
            padding: 0 3rem 3rem;

            .btn {}
          }

          .cs-box {
            height: 50rem;

            .cs {
              // @include posi_c_l;
              // width: 100%;
              font-size: 2.6rem;
              // 
              // color: $clr_blu;
              // text-align: center;
            }
          }
        }
      }

      .table-wrap {
        padding: 8rem 0 0;

        .table-box {
          position: relative;
          overflow-x: scroll;
          overflow-y: hidden;

          table {
            width: 154rem;
            // border-top: 1px solid #d9d9d9;
            // border-right: 1px solid #d9d9d9;
          }

          tr {
            // @include flex_b;
            // border-bottom: 1px solid #d9d9d9;
          }

          th,
          td {
            // border-left: 1px solid #d9d9d9;

            p {
              padding: 2.2rem 0;
              // border-bottom: 1px solid #d9d9d9;
              font-size: 2.6rem;
              // 
              letter-spacing: 0em;
              // line-height: 1.8;
              // text-align: center;
            }

            p:last-of-type {
              // border-bottom: none;
            }
          }

          th:nth-of-type(1),
          td:nth-of-type(1) {
            @include flex_c_c;
            width: calc(100% - 52rem - 38rem - 21rem - 21rem);
          }

          th:nth-of-type(2),
          td:nth-of-type(2) {
            width: 52rem;
          }

          th:nth-of-type(3),
          td:nth-of-type(3) {
            width: 38rem;
          }

          th:nth-of-type(4),
          td:nth-of-type(4) {
            width: 21rem;
          }

          th:nth-of-type(5),
          td:nth-of-type(5) {
            width: 21rem;
          }

          td:nth-of-type(4),
          td:nth-of-type(5) {
            p {
              font-weight: 400;
            }
          }

          th {
            // background: #f2f2f2;
          }

          td {
            // background: #fff;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageService.results-investment ___end
}