@use "../global" as *;

html {
  font-size: 10px;
}

@include mq(pc_max) {
  html {
    font-size: calc(10 / $media_pc * 100vw);
  }
}

@include mq(sp) {
  html {
    font-size: calc(10 / $media_sp * 100vw);
  }
}

@include mq(pc_min) {
  ._sp {
    display: none !important
  }
}

@include mq(sp) {
  ._pc {
    display: none !important
  }
}

* {
  margin: 0;
  padding: 0;
}

html {
  // overflow: auto;
  // overflow-x: hidden;
}

body {
  text-size-adjust: 100%;
  // overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

#__yo {
  overflow: hidden;
}

main#main.main {
  display: block;
  padding-top: 11rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@include mq(sp) {
  main#main.main {
    padding-top: 10rem;
  }
}

section {
  position: relative;
}

img,
svg,
video {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

img,
svg {
  pointer-events: none;
}