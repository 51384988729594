@use "../global" as *;

#pageService.business-sale {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-tab {
    padding: 8rem 0 8rem;

    .copy-wrap {
      .copy-box {
        text-align: center;
      }
    }

    .tab-wrap {
      margin: 8rem 0 0;
    }
  }

  // ______________________________________
  @include mq() {
    .sec-tab {
      padding: 9rem 0 9rem;

      .copy-wrap {
        .copy-box {
          // text-align: center;
        }
      }

      .tab-wrap {
        margin: 9rem 0 0;
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-predear {
    padding: 7rem 0 10rem;

    .copy-wrap {
      position: relative;

      .copy-box {
        text-align: center;
      }

      .logo-box {
        width: 30rem;
        margin: 4rem auto 0;
      }
    }

    .process-wrap {
      margin: 10rem 0 0;
      position: relative;

      .copy-box {
        text-align: center;
      }

      .img-box {
        width: 100rem;
        margin: 4rem auto 0;
      }
    }

    .list-wrap {
      @include flex_b;
      gap: 4rem 0;
      margin: 6rem 0 0;
      position: relative;

      .list-box {
        width: 57.7rem;

        .img {}

        .copy {
          margin: 1rem 0 0;

          p {

            line-height: 1.4;
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-predear {
      padding: 8rem 0 11rem;

      .copy-wrap {
        // position: relative;

        .copy-box {
          // text-align: center;
        }

        .logo-box {
          width: 30rem * 1.25;
          margin: 5rem auto 0;
        }
      }

      .process-wrap {
        margin: 11rem 0 0;
        // position: relative;

        .copy-box {
          // text-align: center;
        }

        .img-box {
          width: 100%;
          margin: 5rem auto 0;
          position: relative;
          overflow-x: scroll;
          overflow-y: hidden;

          .img {
            width: 100rem * 1.5;
          }
        }
      }

      .list-wrap {
        // @include flex_b;
        gap: 5rem 0;
        padding: 0 4rem;
        margin: 7rem 0 0;
        // position: relative;

        .list-box {
          width: 100%;

          .img {}

          .copy {
            margin: 2rem 0 0;

            p {
              // 
              // line-height: 1.4;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-links {
    padding: 7rem 0 8rem;
    background: #E0EFFE;

    .links-wrap {
      .copy-area {
        .copy-box {
          text-align: center;
        }
      }

      .links-area {
        @include flex_b;
        gap: 3rem 0;
        margin: 5rem 0 0;

        .link-box {
          @include transition;
          width: 56rem;
          height: 11rem;
          background: $clr_blu;
          position: relative;
          overflow: hidden;

          .bg {
            @include transition;
          }

          .cover {
            @include transition;
            @include posi_cover;
            background: rgba(#000, .5);
          }

          .copy {
            @include posi_c_l;
            width: 100%;
            color: #fff;
            text-align: center;
          }
        }

        .link-box.predear {
          width: 100%;
          height: 20rem;
          background: #fff;

          .logo {
            @include posi_c_c;
            width: 30rem;
          }

          .btn {
            @include posi_c_r;
            right: 4rem;
          }
        }

        .link-box:hover {
          .bg {
            transform: scale(1.05);
          }

          .cover {
            background: rgba(#000, .75);
          }

          .btn {
            background: $clr_blu;
          }
        }

        .link-box.clr-blu:hover {
          background: $clr_blu2;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-links {
      padding: 8rem 0 9rem;
      // background: #E0EFFE;

      .links-wrap {
        .copy-area {
          .copy-box {
            line-height: 1.4;
            // text-align: center;
          }
        }

        .links-area {
          // @include flex_b;
          gap: 4rem 0;
          padding: 0 4rem;
          margin: 6rem 0 0;

          .link-box {
            width: 100%;
            height: 12rem;
            // background: $clr_blu;
            // position: relative;
            // overflow: hidden;

            .bg {
              // @include transition;
            }

            .cover {
              // @include transition;
              // @include posi_cover;
              // background: rgba(#000, .5);
            }

            .copy {
              // @include posi_c_l;
              // width: 100%;
              // color: #fff;
              // text-align: center;
            }
          }

          .link-box.predear {
            // width: 100%;
            height: 21rem;
            // background: #fff;

            .logo {
              // @include posi_c_c;
              width: 32rem;
            }

            .btn {
              // @include posi_c_r;
              right: 2rem;
            }
          }

          .link-box:hover {
            .bg {
              transform: scale(1.0);
            }

            .cover {
              opacity: 1;
            }

            .btn {
              background: #afafaf;
            }
          }

          .link-box.clr-blu:hover {
            // background: $clr_blu2;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageService.business-sale ___end
}