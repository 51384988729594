@use "../global" as *;

#pageService.results-sale {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-tab {
    padding: 8rem 0 8rem;

    .tab-wrap {}
  }

  // ______________________________________
  @include mq() {
    .sec-tab {
      padding: 9rem 0 9rem;

      .tab-wrap {}
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-sale {
    padding: 8rem 0 15rem;

    .map-wrap {
      .map-box {
        width: 85rem;
        margin: 0 auto;
      }

      .note-box {
        width: 64rem;
        margin: 5rem auto 0;
      }
    }

    .links-wrap {
      @include flex;
      gap: 6rem 6rem;
      width: 78rem;
      margin: 7rem auto 0;

      .links-area {
        width: 36rem;

        .ttl-box {
          padding: 1.6rem 0;
          border-bottom: 1px solid $clr_blu;
        }

        .links-box {
          margin: 1.6rem 0 0;

          .link {
            @include transition;
            @include flex_b_c;
            height: 6rem;
            padding: 0 2.4rem 0 1.6rem;
            border-bottom: 1px solid #D9D9D9;

            span {
              @include transition;
              font-size: 1.4rem;
            }

            i {
              @include transition;
              font-size: 1.4rem;
              font-weight: 900;
              color: $clr_blu;
            }
          }

          .link:hover {
            background: $clr_blu;

            span {
              color: #fff;
            }

            i {
              color: #fff;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-sale {
      padding: 9rem 0 16rem;

      .map-wrap {
        .map-box {
          width: 100%;
          // margin: 0 auto;
        }

        .note-box {
          width: 100%;
          margin: 6rem auto 0;
        }
      }

      .links-wrap {
        @include flex_b;
        gap: 6rem 0rem;
        width: 100%;
        margin: 8rem auto 0;

        .links-area {
          width: 31rem;

          .ttl-box {
            padding: 2.6rem 0;
            // border-bottom: 1px solid $clr_blu;
          }

          .links-box {
            margin: 2.6rem 0 0;

            .link {
              // @include transition;
              // @include flex_b_c;
              height: 6rem * 1.5;
              padding: 0 2rem 0 1rem;
              // border-bottom: 1px solid #D9D9D9;

              span {
                // @include transition;
                font-size: 2.6rem;
              }

              i {
                // @include transition;
                font-size: 2.6rem;
                // font-weight: 900;
                // color: $clr_blu;
              }
            }

            .link:hover {
              // background: $clr_blu;

              span {
                // color: #fff;
              }

              i {
                // color: #fff;
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageService.results-sale ___end
}