@use "../global" as *;

#pageCompany.partnership {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-ps {
    margin: 8rem 0 15rem;

    .ttl-wrap {
      .ttl-box {
        text-align: center;
      }

      .txt-box {
        margin: 7rem 0 0;
      }
    }

    .list-wrap {
      margin: 7rem 0 0;

      .list-box {
        margin: 5rem 0 0;

        .txt {
          margin: 2rem 0 0;
        }

        .list {
          padding: 0 0 0 2rem;
          margin: 2rem 0 0;

          span {
            
          }
        }
      }
    }

    .name-wrap {
      margin: 12rem 0 0;

      .name-box {
        text-align: right;

        .date {}

        .name {
          letter-spacing: 0em;
          margin: 2rem 0 0;
        }
      }
    }

    .note-wrap {
      margin: 8rem 0 0;

      .note-box {
        .note {
          font-size: 1.1rem;
          letter-spacing: 0.05em;
          line-height: 1.7;
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-ps {
      margin: 9rem 0 16rem;

      .ttl-wrap {
        .ttl-box {
          // text-align: center;

          .h2 {
            letter-spacing: 0;
          }
        }

        .txt-box {
          margin: 8rem 0 0;
        }
      }

      .list-wrap {
        margin: 8rem 0 0;

        .list-box {
          margin: 6rem 0 0;

          .h3 {
            padding-left: 2em;
            text-indent: -2em;
            line-height: 1.6;
          }

          .txt {
            text-align: justify;
            margin: 3rem 0 0;
          }

          .list {
            padding: 0 0 0 3rem;
            margin: 3rem 0 0;

            span {
              // 
            }
          }
        }
      }

      .name-wrap {
        margin: 13rem 0 0;

        .name-box {
          // text-align: right;

          .date {}

          .name {
            // letter-spacing: 0em;
            line-height: 1.4;
            margin: 3rem 0 0;
          }
        }
      }

      .note-wrap {
        margin: 9rem 0 0;

        .note-box {
          .note {
            font-size: 2.2rem;
            // letter-spacing: 0.05em;
            // line-height: 1.7;
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageCompany.partnership ___end
}