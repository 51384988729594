@use "../global" as *;

#pageCompany.organization {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-loe {
    padding: 8rem 0 0;

    .ttl-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }
    }

    .loe-wrap {
      margin: 8rem 0 0;
      position: relative;

      .loe-area {
        padding: 4.5rem 4rem;
        background: #fff;
        position: relative;

        .table-box {
          >table {
            width: 100%;
            border-top: 1px solid #d9d9d9;

            tbody {
              tr {
                @include flex_b_c;
                border-bottom: 1px solid #d9d9d9;
              }

              th,
              td {
                padding: 1.2rem 0;
                font-size: 1.4rem;
                line-height: 1.8;
                text-align: left;
              }

              th {
                width: 32rem;
                font-weight: 500;
                letter-spacing: 0;
              }

              td {
                width: calc(100% - 32rem);
                letter-spacing: 0.1em;
              }

              td.td-1 {
                width: 22rem;
              }

              td.td-2 {
                width: calc(100% - 32rem - 22rem);
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-loe {
      padding: 9rem 0 0;

      .ttl-wrap {
        // position: relative;

        .ttl-box {
          // text-align: center;
        }
      }

      .loe-wrap {
        margin: 9rem 0 0;
        // position: relative;

        .loe-area {
          padding: 0rem 0rem;
          // background: #fff;
          // position: relative;

          .table-box {
            >table {
              // width: 100%;
              // border-top: 1px solid #d9d9d9;

              tbody {
                tr {
                  // @include flex_b_c;
                  // border-bottom: 1px solid #d9d9d9;
                }

                th,
                td {
                  padding: 2.2rem 4rem;
                  font-size: 2.6rem;
                  // line-height: 1.8;
                  // text-align: left;
                }

                th {
                  width: 100%;
                  background: #f2f2f2;
                  // font-weight: 500;
                  // letter-spacing: 0;
                }

                td {
                  width: 100%;
                  letter-spacing: 0;
                }

                td.td-1 {
                  width: 100%;
                  padding-right: 0;
                  padding-bottom: 0;
                }

                td.td-2 {
                  width: 100%;
                  padding-top: 0;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec-oc {
    padding: 8rem 0 0;
    margin: 6rem 0 15rem;

    .ttl-wrap {
      position: relative;

      .ttl-box {
        text-align: center;
      }
    }

    .oc-wrap {
      margin: 8rem 0 0;
      position: relative;

      .oc-area {
        position: relative;

        .figure-box {
          .img {
            svg {
              pointer-events: auto;
            }
          }
        }

        .date-box {
          @include posi_t_l;

          .date {
            font-size: 1.4rem;
            letter-spacing: 0;
          }
        }
      }
    }
  }

  // ______________________________________
  @include mq() {
    .sec-oc {
      padding: 9rem 0 0;
      margin: 13rem 0 16rem;

      .ttl-wrap {
        // position: relative;

        .ttl-box {
          // text-align: center;
        }
      }

      .oc-wrap {
        margin: 9rem 0 0;
        // position: relative;

        .oc-area {
          // position: relative;

          .figure-box {
            padding: 6rem 0 0;
            position: relative;
            overflow-x: scroll;

            .img {
              width: 150rem;

              svg {
                pointer-events: auto;
              }
            }
          }

          .date-box {
            // @include posi_t_l;

            .date {
              font-size: 2.4rem;
              // letter-spacing: 0;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageCompany.organization ___end
}