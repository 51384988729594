@use "_setting" as *;

@mixin mq($bp: sp) {
  @media #{map-get($breakpoint, $bp)} {
    @content;
  }
}

@mixin bgimg($url) {
  background: {
    image: url("../../build/img#{$url}");
    repeat: no-repeat;
    size: cover;
    position: center center;
  }

  @content;
}

@mixin object_fit($x: center, $y: center) {
  object-fit: cover;
  object-position: $x $y;
  width: 100%;
  height: 100%;
  @content;
}

@mixin transition {
  transition: all 400ms cubic-bezier(0.360, 0.100, 0.160, 1.000);
  transition-duration: 800ms;
  // transition: all 200ms cubic-bezier(0.320, 0.625, 0.580, 1.000);
  // transition: all 500ms cubic-bezier(0.170, 0.935, 0.305, 1.000);
  // transition: all 1000ms cubic-bezier(0.545, 0.080, 0.520, 0.975);
}

@mixin transition_1 {
  transition: all 400ms cubic-bezier(0.360, 0.100, 0.160, 1.000);
}

@mixin transition_2 {
  transition: all 200ms cubic-bezier(0.320, 0.625, 0.580, 1.000);
}

@mixin transition_3 {
  transition: all 500ms cubic-bezier(0.170, 0.935, 0.305, 1.000);
}

@mixin transition_4 {
  transition: all 1000ms cubic-bezier(0.545, 0.080, 0.520, 0.975);
}

@mixin flex {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex_a {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@mixin flex_b {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin flex_c {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@mixin flex_e {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@mixin flex_s {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@mixin flex_a_s {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

@mixin flex_a_e {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
}

@mixin flex_a_c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

@mixin flex_b_s {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flex_b_e {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

@mixin flex_b_c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@mixin flex_c_s {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex_c_e {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex_c_c {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@mixin flex_e_s {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
}

@mixin flex_e_e {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
}

@mixin flex_e_c {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex_s_s {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex_s_e {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
}

@mixin flex_s_c {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

@mixin posi_t_l {
  position: absolute;
  top: 0;
  left: 0;
}

@mixin posi_t_r {
  position: absolute;
  top: 0;
  right: 0;
}

@mixin posi_b_r {
  position: absolute;
  bottom: 0;
  right: 0;
}

@mixin posi_b_l {
  position: absolute;
  bottom: 0;
  left: 0;
}

@mixin posi_c_c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
}

@mixin posi_t_c {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
}

@mixin posi_c_r {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  margin: auto;
}

@mixin posi_b_c {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
}

@mixin posi_c_l {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  margin: auto;
}

@mixin posi_cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}