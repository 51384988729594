@use "../global" as *;

// ______________________________________
// ______________________________________
// ______________________________________
nav.nav-h {
  ul.ul-p {
    @include flex_c;

    li.li-p {

      >p,
      >a {
        display: block;
        height: 3.5rem;
        padding: 0 2rem;
        cursor: pointer;

        span {
          @include transition;
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
    }

    li.li-p:hover {

      >p,
      >a {
        span {
          color: $clr_blu;
        }
      }
    }
  }

  .child-box {
    @include posi_t_c;
    top: 11rem;
  }

  .child-box.num-1,
  .child-box.num-7 {
    z-index: 4;
  }

  .child-box.num-2,
  .child-box.num-6 {
    z-index: 3;
  }

  .child-box.num-3,
  .child-box.num-5 {
    z-index: 2;
  }

  .child-box.num-4 {
    z-index: 1;
  }

  .child-inner {
    @include flex;
    width: 120rem;
    background: #fff;
  }

  .child-ttl {
    @include flex_c_c;
    width: 27rem;
    background: $clr_blu;

    span {
      font-size: 2.2rem;
      font-weight: 500;
      letter-spacing: 0.15em;
      color: #fff;
    }
  }

  ul.ul-c {
    @include flex;
    gap: 0 4rem;
    width: calc(100% - 27rem);
    padding: 5rem 3.5rem;

    p.li-c-ttl {
      width: 100%;
      font-size: 1.7rem;
      font-weight: 500;
      color: $clr_blu;
      margin: 3.6rem 0 1rem;
    }

    p.li-c-ttl.mt-0 {
      margin-top: 0;
    }

    li.li-c {
      width: 26rem;
      border-top: solid 1px #e0e2e5;
      border-bottom: solid 1px #e0e2e5;

      >a {
        @include flex_s_c;
        gap: 0 1rem;
        padding: 1.5rem 0;

        i {
          @include flex_c_c;
          width: 0.6rem;
        }

        span {
          @include transition;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.4;
        }
      }

      >a:hover {
        span {
          color: $clr_blu;
        }
      }
    }

    li.li-c.bb-none {
      border-bottom: none;
    }
  }
}

#pageCompany nav.nav-h ul.ul-p li.li-p.company>p span,
#pageCompany nav.nav-h ul.ul-p li.li-p.company>a span,
#pageService nav.nav-h ul.ul-p li.li-p.service>p span,
#pageService nav.nav-h ul.ul-p li.li-p.service>a span,
#pageNews nav.nav-h ul.ul-p li.li-p.news>p span,
#pageNews nav.nav-h ul.ul-p li.li-p.news>a span,
#pageSustainability nav.nav-h ul.ul-p li.li-p.sustainability>p span,
#pageSustainability nav.nav-h ul.ul-p li.li-p.sustainability>a span,
#pageLand nav.nav-h ul.ul-p li.li-p.land>p span,
#pageLand nav.nav-h ul.ul-p li.li-p.land>a span,
#pageRecruit nav.nav-h ul.ul-p li.li-p.recruit>p span,
#pageRecruit nav.nav-h ul.ul-p li.li-p.recruit>a span,
#pageInquiry nav.nav-h ul.ul-p li.li-p.inquiry>p span,
#pageInquiry nav.nav-h ul.ul-p li.li-p.inquiry>a span {
  color: $clr_blu;
}


// ______________________________________
@include mq() {
  nav.nav-h {
    @include posi_t_r;
    position: fixed;
    top: 10rem;
    right: 0rem;
    z-index: 9999;
    width: 100vw;
    height: calc(100svh - 10rem);
    background: #fff;

    ul.ul-p {
      // @include flex_c;
      display: block;
      overflow: scroll;
      height: 100%;

      li.li-p {
        border-top: solid 1px #d9d9d9;
        position: relative;

        >p,
        >a {
          // display: block;
          @include flex_s_c;
          height: 12rem;
          padding: 0 4rem;
          position: relative;
          // cursor: pointer;

          span {
            // @include transition;
            font-size: 2.6rem;
            // font-weight: 500;
          }
        }

        >i {
          @include posi_t_r;
          width: 11rem;
          height: 12rem;
        }

        i.plus::before,
        i.plus::after {
          @include transition;
          @include posi_c_c;
          content: "";
          background: $clr_blu;
        }

        i.plus::before {
          width: 2.4rem;
          height: 0.4rem;
        }

        i.plus::after {
          width: 0.4rem;
          height: 2.4rem;
        }

        i.arw::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 1.8rem;
          height: 1.8rem;
          border-top: solid 0.4rem $clr_blu;
          border-right: solid 0.4rem $clr_blu;
          transform: rotate(45deg);
        }
      }

      li.li-p.act {
        >i.plus::after {
          opacity: 0;
        }
      }

      li.li-p:hover {

        >p,
        >a {
          span {
            color: #000;
          }
        }
      }
    }

    .child-box {
      @include transition;
      // @include posi_t_c;
      // top: 11rem;
      position: static;
      transform: none;
      overflow: hidden;
    }

    .child-box.num-1,
    .child-box.num-7 {
      // z-index: 4;
    }

    .child-box.num-2,
    .child-box.num-6 {
      // z-index: 3;
    }

    .child-box.num-3,
    .child-box.num-5 {
      // z-index: 2;
    }

    .child-box.num-4 {
      // z-index: 1;
    }

    .child-inner {
      // @include flex;
      display: block;
      width: 100%;
      // background: #fff;
    }

    .child-ttl {
      // @include flex_c_c;
      // width: 27rem;
      // background: $clr_blu;
      display: none;

      span {
        // font-size: 2.2rem;
        // font-weight: 500;
        // letter-spacing: 0.15em;
        // color: #fff;
      }
    }

    ul.ul-c {
      // @include flex;
      // gap: 0 4rem;
      display: block;
      width: 100% !important;
      height: auto;
      padding: 0;
      background: rgba($clr_blu, .1);

      p.li-c-ttl {
        // width: 100%;
        padding: 4rem 4rem 2rem;
        font-size: 2.6rem;
        // font-weight: 500;
        // color: $clr_blu;
        margin: 0;
      }

      p.li-c-ttl.mt-0 {
        // margin-top: 0;
      }

      li.li-c {
        width: 100% !important;
        padding: 0 4rem;
        border-top: solid 1px #e0e2e5;
        border-bottom: none;

        >a {
          // @include flex_s_c;
          gap: 0 2rem;
          height: 10rem;
          padding: 0;

          i {
            // @include flex_c_c;
            width: 0.6rem * 1.5;
          }

          span {
            // @include transition;
            font-size: 2.4rem;
            // font-weight: 500;
            // line-height: 1.4;
          }
        }

        >a:hover {
          span {
            // color: $clr_blu;
          }
        }
      }

      li.li-c.bb {
        // border-bottom: none;
      }
    }
  }

  nav.nav-h {
    @include transition;
    right: -100vw;
  }

  nav.nav-h.act {
    right: 0;
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
button.nav-btn {
  display: none;
}

// ______________________________________
@include mq() {
  button.nav-btn {
    width: 33px;
    height: 18px;
    position: fixed;
    top: calc(5rem - 9px);
    right: 2rem;
    z-index: 9999;
  }

  button.nav-btn span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #dadada;
    background-color: $clr_blu;
  }

  button.nav-btn,
  button.nav-btn span {
    display: inline-block;
    transition: all .5s;
    box-sizing: border-box;
  }

  button.nav-btn span:nth-of-type(1) {
    top: 0;
  }

  button.nav-btn span:nth-of-type(2) {
    top: 8px;
  }

  button.nav-btn span:nth-of-type(3) {
    bottom: 0;
  }

  button.nav-btn span:nth-of-type(1) {
    animation: btn07-bar01 .75s forwards;
  }

  @keyframes btn07-bar01 {
    0% {
      transform: translateY(8px) rotate(35deg);
    }

    50% {
      transform: translateY(8px) rotate(0);
    }

    100% {
      transform: translateY(0) rotate(0);
    }
  }

  button.nav-btn span:nth-of-type(2) {
    transition: all .25s .25s;
    opacity: 1;
  }

  button.nav-btn span:nth-of-type(3) {
    animation: btn07-bar03 .75s forwards;
  }

  @keyframes btn07-bar03 {
    0% {
      transform: translateY(-8px) rotate(-35deg);
    }

    50% {
      transform: translateY(-8px) rotate(0);
    }

    100% {
      transform: translateY(0) rotate(0);
    }
  }

  button.nav-btn.act span:nth-of-type(1) {
    animation: active-btn07-bar01 .75s forwards;
  }

  @keyframes active-btn07-bar01 {
    0% {
      transform: translateY(0) rotate(0);
    }

    50% {
      transform: translateY(8px) rotate(0);
    }

    100% {
      transform: translateY(8px) rotate(35deg);
    }
  }

  button.nav-btn.act span:nth-of-type(2) {
    opacity: 0;
  }

  button.nav-btn.act span:nth-of-type(3) {
    animation: active-btn07-bar03 .75s forwards;
  }

  @keyframes active-btn07-bar03 {
    0% {
      transform: translateY(0) rotate(0);
    }

    50% {
      transform: translateY(-8px) rotate(0);
    }

    100% {
      transform: translateY(-8px) rotate(-35deg);
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
nav.nav-f {
  ul.ul-p {
    @include flex_b;

    li.li-p {

      p.p-p,
      a.a-p {
        @include transition;
        display: block;

        span {
          font-size: 1.4rem;
          font-weight: 500;
        }
      }

      a.a-p:hover {
        opacity: .7;
      }

      p.p-p.mt,
      a.a-p.mt {
        margin: 2rem 0 0;
      }
    }
  }

  ul.ul-c {
    margin: 2rem 0 0;

    li.li-c {
      margin: 1rem 0 0;

      a.a-c {
        @include transition;

        span {
          font-size: 1.3rem;
          color: #898989;
        }
      }

      a.a-c:hover {
        opacity: .7;
      }
    }
  }
}

// ______________________________________
@include mq() {
  nav.nav-f {
    ul.ul-p {
      // @include flex_b;
      gap: 5rem 0;

      li.li-p {

        p.p-p,
        a.a-p {
          // @include transition;

          span {
            font-size: 2.4rem;
            // font-weight: 500;
          }
        }

        a.a-p:hover {
          // opacity: .7;
        }
      }

      li.li-p:nth-of-type(odd) {
        width: 53%;
      }

      li.li-p:nth-of-type(even) {
        width: 47%;
      }
    }

    ul.ul-c {
      margin: 2.8rem 0 0;

      li.li-c {
        margin: 1.6rem 0 0;

        a.a-c {
          // @include transition;

          span {
            font-size: 2.2rem;
            // color: #898989;
          }
        }

        a.a-c:hover {
          // opacity: .7;
        }
      }
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
nav.nav-sub {
  ul {
    @include flex_c;

    li {
      a {
        display: block;
        height: 24rem;
        position: relative;
        overflow: hidden;

        img {
          @include transition;
        }

        .cover {
          @include transition;
          @include posi_cover;
          background: rgba(#000, .3);
        }

        .ttl {
          @include posi_c_l;
          width: 100%;
          font-size: 2.3rem;
          line-height: 1.6;
          color: #fff;
          text-align: center;
          text-shadow:
            0 0 0.2rem rgba(0, 0, 0, .3),
            0 0 0.4rem rgba(0, 0, 0, .3),
            0 0 0.6rem rgba(0, 0, 0, .3),
            0 0 0.8rem rgba(0, 0, 0, .3),
            0 0 1.0rem rgba(0, 0, 0, .3),
            0 0 1.2rem rgba(0, 0, 0, .3),
            0 0 1.4rem rgba(0, 0, 0, .3),
            0 0 1.6rem rgba(0, 0, 0, .3),
            0 0 1.8rem rgba(0, 0, 0, .3),
            0 0 2.0rem rgba(0, 0, 0, .3);
        }
      }

      a:hover {
        img {
          transform: scale(1.05);
        }

        .cover {
          background: rgba(#000, .0);
        }
      }
    }

    li.child {
      border-top: solid 1px #fff;
      position: relative;

      a {
        @include transition;
        height: 8rem;
        background: $clr_blu;

        .ttl {
          text-shadow: none;
        }
      }

      a:hover {
        background: $clr_blu2;
      }
    }

    li.child::before {
      content: "";
      @include posi_t_r;
      z-index: 1;
      width: 1px;
      height: 100%;
      background: #fff;
    }

    li.child:last-of-type::before {
      display: none;
    }
  }
}

nav.nav-sub.column-2 {
  ul {
    li {
      width: calc(100% / 4);
    }
  }
}

nav.nav-sub.column-4 {
  ul {
    li {
      width: calc(100% / 4);
    }
  }
}

nav.nav-sub.column-6 {
  ul {
    li {
      width: calc(100% / 6);
    }
  }
}

#pageCompany.outline nav.nav-sub ul li.outline a,
#pageCompany.message nav.nav-sub ul li.message a,
#pageCompany.philosophy nav.nav-sub ul li.philosophy a,
#pageCompany.organization nav.nav-sub ul li.organization a,
#pageCompany.location nav.nav-sub ul li.location a,
#pageCompany.partnership nav.nav-sub ul li.partnership a,

#pageService.business-sale nav.nav-sub ul li.business-sale a,
#pageService.business-lease nav.nav-sub ul li.business-lease a,
#pageService.business-investment nav.nav-sub ul li.business-investment a,
#pageService.business-senior nav.nav-sub ul li.business-senior a,
#pageService.results-sale nav.nav-sub ul li.results-sale a,
#pageService.results-lease nav.nav-sub ul li.results-lease a,
#pageService.results-investment nav.nav-sub ul li.results-investment a,
#pageService.quality nav.nav-sub ul li.quality a,

#pageNews.release nav.nav-sub ul li.release a,
#pageNews.information nav.nav-sub ul li.information a,

#pageSustainability.green-building nav.nav-sub ul li.green-building a,
#pageSustainability.sdgs nav.nav-sub ul li.sdgs a {
  img {
    transform: scale(1.05);
  }

  .cover {
    background: rgba(#000, .0);
  }
}

// ______________________________________
@include mq() {
  nav.nav-sub {
    ul {
      // @include flex;

      li {
        width: calc(100% / 2) !important;

        a {
          // display: block;
          height: 37rem;
          // position: relative;
          // overflow: hidden;

          img {
            // @include transition;
          }

          .cover {
            // @include transition;
            // @include posi_cover;
            // background: rgba(#000, .3);
          }

          .ttl {
            // @include posi_c_l;
            // width: 100%;
            font-size: 3.4rem;
            // line-height: 1.6;
            // color: #fff;
            // text-align: center;
            // text-shadow:
            //   0 0 0.2rem rgba(0, 0, 0, .4),
            //   0 0 0.4rem rgba(0, 0, 0, .4),
            //   0 0 0.6rem rgba(0, 0, 0, .4),
            //   0 0 0.8rem rgba(0, 0, 0, .4),
            //   0 0 1.0rem rgba(0, 0, 0, .4),
            //   0 0 1.2rem rgba(0, 0, 0, .4),
            //   0 0 1.4rem rgba(0, 0, 0, .4),
            //   0 0 1.6rem rgba(0, 0, 0, .4),
            //   0 0 1.8rem rgba(0, 0, 0, .4),
            //   0 0 2.0rem rgba(0, 0, 0, .4);
          }
        }

        a:hover {
          img {
            // transform: scale(1.05);
          }

          .cover {
            // background: rgba(#000, .0);
          }
        }
      }

      li.child {
        // border-top: solid 1px #fff;
        // position: relative;

        a {
          // @include transition;
          height: 16rem;
          // background: $clr_blu;

          .ttl {
            // text-shadow: none;
          }
        }

        a:hover {
          // background: $clr_blu2;
        }
      }

      li.child::before {
        // content: "";
        // @include posi_t_r;
        // z-index: 1;
        // width: 1px;
        // height: 100%;
        // background: #fff;
      }

      li.child:last-of-type::before {
        // display: none;
      }

      li.child:nth-of-type(even)::before {
        display: none;
      }
    }
  }
}

// ______________________________________
// ______________________________________
// ______________________________________
nav.nav-breadcrumbs {
  @include posi_t_l;
  z-index: 2;
  top: 40rem;
  width: 100%;
  padding: 0 8rem;
  background: #fff;
  border-top: 1px solid #ebebeb;

  ul {
    @include flex_s_c;
    gap: 0 0.4rem;
    padding: 0.4rem 0;

    li {
      @include flex_s_c;
      gap: 0 0.4rem;
      font-size: 1.1rem;

      span {
        font-size: 1.4rem;
        position: relative;
        top: -0.2rem;
      }

      a {
        @include transition;
      }

      a.home {
        font-size: 1.7rem;
      }

      a:hover {
        opacity: .7;
      }
    }
  }
}

#pageTop nav.nav-breadcrumbs,
body.page-404 nav.nav-breadcrumbs {
  display: none;
}

// ______________________________________
@include mq() {
  nav.nav-breadcrumbs {
    // @include posi_t_l;
    // z-index: 2;
    top: 39rem;
    // width: 100%;
    padding: 0 2rem;
    // background: #fff;
    // border-top: 1px solid #ebebeb;

    ul {
      // @include flex_s_c;
      gap: 0 0.8rem;
      padding: 0.8rem 0;

      li {
        // @include flex_s_c;
        gap: 0 0.8rem;
        font-size: 2rem;

        span {
          font-size: 2.4rem;
          // position: relative;
          // top: -0.1rem;
        }

        a {
          // @include transition;
        }

        a.home {
          font-size: 2.6rem;
        }

        a:hover {
          // opacity: .7;
        }
      }
    }
  }
}