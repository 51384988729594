// @font-face {
//   font-family: 'ShipporiMincho';
//   src: url('../../../fonts/ShipporiMincho-OTF-Regular.woff2') format('woff2'),
//        url('../../../fonts/ShipporiMincho-OTF-Regular.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

body {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.0em;
  color: #000;
  font-feature-settings: "palt";

  h1,
  h2,
  h3,
  span,
  sup,
  a,
  button {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
  }
}

// .f-sm {
//   font-family: 'ShipporiMincho', serif;
// }

// 100 = Thin, Hairline
// 200 = Extra Light, Ultra Light
// 300 = Light
// 400 = Normal, Regular, Book
// 500 = Medium
// 600 = Semi Bold, Demi Bold
// 700 = Bold
// 800 = Extra Bold, Ultra Bold heavy
// 900 = Black, Heavy