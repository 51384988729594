@use "../global" as *;

// ______________________________________
// ______________________________________
// ______________________________________
footer.footer {
  .subpage-wrap {}

  .logo-wrap {
    padding: 10rem 0 0;

    .logo-box {
      text-align: center;

      .logo {
        display: inline-block;
        width: 40rem;
      }

      .note {
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        margin: 1rem 0 0;
      }
    }
  }

  .nav-wrap {
    margin: 6rem 0 0;
  }

  .links-wrap {
    margin: 6rem 0 0;

    ul {
      @include flex;
      gap: 0 2rem;

      li {
        position: relative;

        a {
          @include transition;

          span {
            font-size: 1.4rem;
          }
        }

        a:hover {
          opacity: .7;
        }
      }

      li::before {
        @include posi_c_l;
        left: -1rem;
        content: "";
        width: 1px;
        height: 1.2rem;
        background: #898989;
      }

      li:first-of-type::before {
        display: none;
      }
    }
  }

  .cr-wrap {
    padding: 0 0 4rem;
    margin: 2rem 0 0;

    .cr-box {
      small {
        font-size: 1.3rem;
      }
    }
  }
}

// ______________________________________
@include mq() {
  footer.footer {
    .subpage-wrap {}

    .logo-wrap {
      padding: 11rem 0 0;

      .logo-box {
        // text-align: center;

        .logo {
          // display: inline-block;
          width: 40rem * 1.5;
        }

        .note {
          font-size: 2.4rem;
          // letter-spacing: 0.1em;
          margin: 2rem 0 0;
        }
      }
    }

    .nav-wrap {
      margin: 7rem 0 0;
    }

    .links-wrap {
      margin: 7rem 0 0;

      ul {
        // @include flex;
        gap: 3rem 0;

        li {
          // position: relative;

          a {
            // @include transition;

            span {
              font-size: 2.2rem;
            }
          }

          a:hover {
            // opacity: .7;
          }
        }

        li:nth-of-type(odd) {
          width: 47%;
        }

        li:nth-of-type(even) {
          width: 53%;
        }

        li::before {
          // @include posi_c_l;
          // left: -1rem;
          // content: "";
          // width: 1px;
          // height: 1.2rem;
          // background: #898989;
          display: none;
        }

        li:first-of-type::before {
          // display: none;
        }
      }
    }

    .cr-wrap {
      padding: 0 0 5rem;
      margin: 5rem 0 0;

      .cr-box {
        small {
          font-size: 2.2rem;
        }
      }
    }
  }
}