@use "../global" as *;

#pageNews {

  // ______________________________________
  // ______________________________________
  // ______________________________________
  .news-com-sec-news {
    padding: 8rem 0 15rem;
    background: #F2F2F2;

    .news-wrap {
      background: #fff;

      .news-area {
        .list-box {
          @include transition;
          padding: 0 10rem;
          overflow: hidden;

          .list {
            @include flex_s_c;
            padding: 2rem 0 2rem;
            border-bottom: solid 1px #d9d9d9;
          }

          .list:last-of-type {
            border-bottom: none;
          }

          .list-date {
            @include flex_s_c;
            width: 10rem;
            height: 2.5rem;

            span {
              letter-spacing: 0;
              line-height: 1;
              color: $clr_blu;
            }
          }

          .list-label {
            @include flex_s_s;
            gap: 0.4rem;
            width: calc(100% - 10rem);

            span {
              @include flex_c_c;
              min-width: 11.5rem;
              height: 2.5rem;
              padding: 0 2rem 0.2rem;
              background: $clr_blu;
              border-radius: 100vh;
              font-size: 1.3rem;
              color: #fff;
            }
          }

          .list-txt {
            width: 100%;
            letter-spacing: 0;
            line-height: 1.4;
            margin: 1rem 0 0;

            a {
              @include transition;
            }

            a:hover {
              color: $clr_blu;
            }
          }
        }

        .btn-box {}
      }
    }
  }

  // ______________________________________
  @include mq() {
    .news-com-sec-news {
      padding: 9rem 0 16rem;
      // background: #F2F2F2;

      .news-wrap {
        // background: #fff;

        .news-area {
          .list-box {
            // @include transition;
            padding: 0 4rem;
            // overflow: hidden;

            .list {
              // @include flex_s_c;
              padding: 3rem 0 3rem;
              // border-bottom: solid 1px #d9d9d9;
            }

            .list:last-of-type {
              // border-bottom: none;
            }

            .list-date {
              // @include flex_s_c;
              width: 10rem * 1.5;
              height: 2.5rem * 1.5;

              span {
                // letter-spacing: 0;
                // line-height: 1;
                // color: $clr_blu;
              }
            }

            .list-label {
              // @include flex_s_s;
              gap: 0.8rem;
              width: calc(100% - 10rem * 1.5);

              span {
                // @include flex_c_c;
                min-width: 11.5rem * 1.5;
                height: 2.5rem * 1.5;
                // padding: 0 2rem 0.2rem;
                // background: $clr_blu;
                // border-radius: 100vh;
                font-size: 2.0rem;
                // color: #fff;
              }
            }

            .list-txt {
              // width: 100%;
              // letter-spacing: 0;
              // line-height: 1.4;
              margin: 2rem 0 0;

              a {
                // @include transition;
              }

              a:hover {
                // color: $clr_blu;
              }
            }
          }

          .btn-box {}
        }
      }
    }
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @include mq() {}

  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // pageNews ___end
}